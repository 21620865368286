import { Alert, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import BottomNavigation from "./components/BottomNavigation"
import BottomWave from "./components/BottomWave"
import Header from "./components/Header"
import PoweredBy from "./components/PoweredBy"
import ReadonlyAssessment from "./components/ReadonlyAssessment"
import Sidebar from "./components/Sidebar"
import Caregivers from "./pages/Caregivers"
import Carereceivers from "./pages/Carereceivers"
import CaseDetails from "./pages/CaseDetails"
import Cases from "./pages/Cases"
import Home from "./pages/Home"
import NewAssessment from "./pages/NewAssessment"
import Users from "./pages/Users"
import { setAlert } from "./store/auth/reducers"

const Paths = () => {

    const { alert, authDetails } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const handleAlertClose = () => {
        dispatch(setAlert({ open: false, message: '', severity: 'success' }))
    }

    return (
        <div className="tw-h-screen tw-w-full tw-bg-sky-50">
            <Header />
            <div className="tw-h-[calc(100vh-64px)] tw-flex tw-z-10 tw-relative">
                <Sidebar />
                <div className="tw-px-1 md:tw-px-4 tw-mb-[56px] sm:tw-mb-0 tw-py-r tw-flex-1 tw-overflow-y-auto">
                    <Routes>
                        <Route path="/" exact element={<Home />} />
                        { authDetails.role === 2 && <Route path="/users" element={<Users />} /> }
                        <Route path="/new-assessment" element={<NewAssessment />} />
                        <Route path="/caregivers" element={<Caregivers />} />
                        <Route path="/carereceivers" element={<Carereceivers />} />
                        <Route path="/cases" element={<Cases />} />
                        <Route path="/case/:id" element={<CaseDetails />} />
                        <Route path="/assessment/:id" element={<ReadonlyAssessment />} />
                    </Routes>
                </div>
            </div>
            <BottomWave />
            <BottomNavigation />
            <PoweredBy />
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: "top", horizontal: "right"}}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Paths