import { reassessmentReason, relationshipOptions, stateOptions } from "./menu"

export const getRole = (role) => {
    switch (role) {
        case 1:
            return 'Screener'
        case 2:
            return 'Admin'
        default:
            return 'Unknown'
    }
}

export const getRelationship = (relationship) => {
    return relationship.toString() === "0" ? {text: "", value: ""} : relationshipOptions.find((item) => item.value.toString() === relationship.toString())
}

export const getReassessmentReason = (reason) => {
    return (reason.toString() === "0" || reason.toString().trim() === "") ? {text: "", value: ""} : reassessmentReason.find((item) => item.value.toString() === reason.toString())
}

export const getState = (state) => {
    let s = stateOptions.find((item) => item.value === state)
    return s ? s : {text: "", value: ""}
}

export function debounce(func, wait, immediate) {
    var timeout;

    return (...args) => {
        var context = this;

        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}