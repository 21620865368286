import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"

const CaregiverPresent = ({ open, connected, onClose, onSuccess }) => {

    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">Caregiver Present</span>
            </DialogTitle>
            <DialogContent>
                { connected ?
                    <p className="tw-text-lg tw-text-gray-700 tw-mb-4">The caregiver is already connected to another carereceiver. Do you want to proceed with reassessment?</p>
                    :
                    <p className="tw-text-lg tw-text-gray-700 tw-mb-4">The caregiver with email already exist. Do you want to add new carereceiver for the caregiver?</p>
                }
            </DialogContent>
            <DialogActions>
                <Button color="error" variant="outlined" onClick={onClose}>No, Cancel</Button>
                <Button color="success" variant="contained" onClick={onSuccess}>Yes, Proceed</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CaregiverPresent