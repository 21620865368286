import logo from '../assets/images/logo2.png'
import { RiAccountCircleLine } from 'react-icons/ri'
import { MdLogout } from 'react-icons/md'
import { Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoggedIn } from '../store/auth/reducers'
import { NavLink } from 'react-router-dom'
import { getRole } from '../data/commons'

const Header = () => {

    const [menuEl, setMenuEl] = useState(null)
    const open = Boolean(menuEl)
    const dispatch = useDispatch()
    const { authDetails } = useSelector(state => state.auth)

    const logout = () => {
        dispatch(setIsLoggedIn(false))
    }

    return (
        <header className="tw-z-10 tw-relative">
            <nav className="tw-bg-sky-50 tw-h-16 tw-flex tw-justify-between tw-items-center tw-px-2 sm:tw-px-4">
                <NavLink to="/" className="tw-flex tw-items-center tw-relative">
                    <img src={logo} alt="logo" className="tw-w-[264px] tw-mr-2" />
                </NavLink>
                <div className="tw-flex tw-items-center tw-cursor-pointer" onClick={ ev => setMenuEl(ev.target) }>
                    <RiAccountCircleLine className="tw-text-sky-300 tw-text-3xl tw-mr-2" />
                    <span className="tw-text-lg tw-text-sky-600 tw-font-bold tw-hidden sm:tw-block">{ authDetails.name } </span>
                </div>
            </nav>
            <Menu
                anchorEl={menuEl}
                open={open}
                onClose={() => setMenuEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="tw-min-w-[250px] -tw-my-2">
                    <div className="tw-bg-slate-100 tw-pt-4 tw-pb-2 tw-px-3">
                        <div className="tw-text-xs tw-font-semibold tw-text-sky-500">
                            LoggedIn as
                        </div>
                        <div className="tw-pt-1 tw-flex tw-justify-between">
                            <span className="tw-text-sky-600 tw-font-bold">{ authDetails.name }</span>
                            <div>
                                <span className="tw-bg-sky-200 tw-text-sky-600 tw-ml-1 tw-text-xs tw-py-0.5 tw-px-3 tw-rounded-full tw-font-semibold">{ getRole(authDetails.role) }</span>
                            </div>
                        </div>
                    </div>
                    <div className="tw-bg-sky-100 tw-py-2">
                        <MenuItem onClick={ logout }>
                            <span className="tw-flex tw-gap-2 tw-items-center tw-text-sm">
                                <MdLogout className="tw-text-xl"/>Logout
                            </span>
                        </MenuItem>
                    </div>
                </div>
            </Menu>
        </header>
    )
}

export default Header