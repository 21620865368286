import { FaUsersCog } from "react-icons/fa"
import { HiHome, HiDocumentAdd, HiUserGroup } from "react-icons/hi"
import { NavLink } from "react-router-dom"

const Home = () => {

    return (
        <div className="tw-w-full">
            <div className="tw-font-bold tw-text-2xl tw-bg-gradient-to-tr tw-from-blue-500 tw-to-sky-500 tw-inline-block tw-bg-clip-text tw-text-transparent tw-mt-4 tw-ml-2">Welcome to the Indiana Pathways Assessment</div>
            <div className="tw-mt-10 tw-ml-4 tw-flex tw-flex-col tw-gap-8">
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
                    <div className="tw-w-full">
                        <NavLink to="/" className="tw-flex tw-gap-2 tw-items-center tw-bg-sky-100 tw-py-4 tw-px-4 tw-transition-all tw-rounded-lg tw-w-5/6 xl:tw-w-3/5 tw-border-l-8 tw-border-rose-400">
                            <span className="tw-bg-gradient-to-tr tw-from-rose-500 tw-to-sky-500 tw-bg-clip-text tw-flex tw-gap-2 tw-items-center">
                                <HiHome className="tw-text-3xl tw-text-rose-400" />
                                <span className="tw-text-xl tw-font-bold tw-text-transparent">Home</span>
                            </span>
                        </NavLink>
                    </div>
                    <div className="tw-w-full">
                        <NavLink to="/new-assessment" className="tw-flex tw-gap-2 tw-items-center tw-bg-sky-100 tw-py-4 tw-px-4 tw-transition-all tw-rounded-lg tw-w-5/6 xl:tw-w-3/5 tw-border-l-8 tw-border-violet-500">
                            <span className="tw-bg-gradient-to-tr tw-from-violet-500 tw-to-sky-500 tw-bg-clip-text tw-flex tw-gap-2 tw-items-center">
                                <HiDocumentAdd className="tw-text-3xl tw-text-violet-500" />
                                <span className="tw-text-xl tw-font-bold tw-text-transparent">New Assessment</span>
                            </span>
                        </NavLink>
                    </div>
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
                    <div className="tw-w-full">
                        <NavLink to="/cases" className="tw-flex tw-gap-2 tw-items-center tw-bg-sky-100 tw-py-4 tw-px-4 tw-transition-all tw-rounded-lg tw-w-5/6 xl:tw-w-3/5 tw-border-l-8 tw-border-teal-500">
                            <span className="tw-bg-gradient-to-tr tw-from-teal-500 tw-to-sky-500 tw-bg-clip-text tw-flex tw-gap-2 tw-items-center">
                                <HiUserGroup className="tw-text-3xl tw-text-teal-500" />
                                <span className="tw-text-xl tw-font-bold tw-text-transparent">Cases/Reports/search</span>
                            </span>
                        </NavLink>
                    </div>
                    <div className="tw-w-full">
                        <NavLink to="/users" className="tw-flex tw-gap-2 tw-items-center tw-bg-sky-100 tw-py-4 tw-px-4 tw-transition-all tw-rounded-lg tw-w-5/6 xl:tw-w-3/5 tw-border-l-8 tw-border-cyan-500">
                            <span className="tw-bg-gradient-to-tr tw-from-cyan-500 tw-to-pink-500 tw-bg-clip-text tw-flex tw-gap-2 tw-items-center">
                                <FaUsersCog className="tw-text-3xl tw-text-cyan-500" />
                                <span className="tw-text-xl tw-font-bold tw-text-transparent">System Users</span>
                            </span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home