import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator"
import { reassessmentReason } from "../data/menu"

const ReassessmentReason = ({ open, reassessment=true, onClose, onSubmit }) => {

    const [reason, setReason] = useState(1)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('sm'))

    const label = useMemo(() => {
        return reassessment ? "Reassessment Reason" : "Assessment Reason"
    }, [reassessment])

    const submit = () => {
        onSubmit(reason)
    }

    useEffect(() => {
        setReason(1)
    }, [open])

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">{label}</span>
            </DialogTitle>
            <DialogContent>
                <ValidatorForm onSubmit={() => {}}>
                    <div className="tw-py-4 tw-flex tw-flex-col tw-gap-1">
                        <SelectValidator name="assessment" label={label} value={reason} onChange={ev => setReason(ev.target.value)} validators={['required']} errorMessages={['This field is required']} className="tw-w-80 sm:tw-w-96">
                            {
                                reassessmentReason.map((reason, index) => (
                                    <MenuItem key={index} value={reason.value}>{reason.text}</MenuItem>
                                ))
                            }
                        </SelectValidator>
                    </div>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="success" onClick={submit}>Start Assessment</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReassessmentReason