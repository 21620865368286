import Lottie from "lottie-react";
import { NavLink } from "react-router-dom";
import UploadComplete from "../assets/images/upload-complete.json"

const AssessmentCompleted = () => {

    return (
        <div className="tw-flex tw-flex-col tw tw-justify-center tw-items-center tw-w-full">
            <div className="tw-w-80 md:tw-w-96">
                <Lottie animationData={UploadComplete} loop={false} />
            </div>
            <NavLink to="/" className="tw-bg-sky-600 tw-text-white tw-py-1 tw-px-8 tw-mb-10 tw-rounded-lg">Back to Home</NavLink>
        </div>
    )
}

export default AssessmentCompleted