import { Button, IconButton } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useRef, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Moment from "react-moment"
import API from "../axios/API"
import DataLoading from "../components/DataLoading"
import { getRelationship } from "../data/commons"
import { FaUserPlus, FaUserClock } from "react-icons/fa"
import CarereceiverForm from "./CarereceiverForm"
import ReassessmentReason from "./ReassessmentReason"
import ActivateCase from "./ActivateCase"
import { IoMdCloseCircle } from "react-icons/io"

const SubscriberSearch = ({ startDemographics }) => {

    const form = useRef(null)
    const [subscriberId, setSubscriberId] = useState("")
    const [searchedSubscriberId, setSearchedSubscriberId] = useState("")
    const [loading, setLoading] = useState(false)
    const [carereceiver, setCarereceiver] = useState({})
    const [assessments, setAssessments] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showCarereceiverForm, setShowCarereceiverForm] = useState(false)
    const [showReassessmentReason, setShowReassessmentReason] = useState(false)
    const [reassessmentId, setReassessmentId] = useState({case_id: 0, assessment_id: 0})
    const [alterCaseId, setAlterCaseId] = useState(0)
    const [alterCaseIsActive, setAlterCaseIsActive] = useState(false)
    const [alterCaseOpen, setAlterCaseOpen] = useState(false)

    const submitForm = () => {
        if(subscriberId !== "") {
            setSearchedSubscriberId(subscriberId)
            setSubscriberId("")
            form.current.resetValidations()
        }
    }
    const startAlterCase = (id) => {
        setAlterCaseId(id)
        setAlterCaseIsActive(assessments.find(row => row.case_id === id).is_active)
        setAlterCaseOpen(true)
    }
    const caseAltered = () => {
        setAlterCaseOpen(false)
        getData()
    }

    const columns = [
        { field: "cg_first_name", headerName: "Caregiver",  renderCell: (params) => (
                <span>
                    { params.row.cg_id === "" ?
                        <span className="tw-text-yellow-600">No Caregiver Available</span>
                    : <div className="tw-flex tw-gap-2 tw-items-center">
                        {
                            params.row.is_active && <Button size="small" variant="contained" color="primary" onClick={() => reassess(params.row.case_id, params.row.id)}>Reassess</Button>
                        }
                        <Button type="button" size="small" variant="contained" color={params.row.is_active ? 'error' : 'success'} onClick={() => startAlterCase(params.row.case_id)}>{params.row.is_active ? 'Deactivate' : 'Activate'}</Button>
                        <span>{params.row.cg_first_name} {params.row.cg_last_name}</span>
                    </div> }
                </span>
            ), minWidth: 350, flex: 1 },
        { field: "relationship_id", headerName: "Relationship", minWidth: 150, flex: 1, valueGetter: (params) => getRelationship(params.row.relationship_id).text },
        { field: "cg_email", headerName: "Email", minWidth: 200, flex: 1 },
        { field: "cg_phone", headerName: "Phone", minWidth: 150, flex: 1 },
        { field: "cg_address", headerName: "Address", minWidth: 200, flex: 1 },
        { field: "last_assessment_date", headerName: "Last Assess Date", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.last_assessment_date}</Moment> }
    ]

    const getData = () => {
        if(searchedSubscriberId !== "") {
            setLoading(true)
            API.post("/get-assessments-by-subscriber-id", { subscriber_id: searchedSubscriberId })
                .then(res => {
                    if(res.data.length > 0) {
                        setCarereceiver({id: res.data[0].cr_id, first_name: res.data[0].cr_first_name, last_name: res.data[0].cr_last_name})
                        setAssessments(res.data)
                    } else {
                        setCarereceiver({})
                        setAssessments([])
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                }
            )
        }
    }
    const noavailablecaregiver = () => {
        setShowCarereceiverForm(true)
    }
    const addedNoCarereceiverAvailable = () => {
        setShowCarereceiverForm(false)
        getData()
    }
    const reassess = (case_id, assessment_id) => {
        setReassessmentId({case_id, assessment_id})
        setShowReassessmentReason(true)
    }
    const addNewCaregiver = () => {
        setReassessmentId({case_id: 0, assessment_id: 0})
        setShowReassessmentReason(true)
    }
    const startAssessment = reason => {
        setShowReassessmentReason(false)
        startDemographics(reassessmentId.case_id, searchedSubscriberId, reason, carereceiver.id || 0)
    }

    useEffect(() => {
        ValidatorForm.addValidationRule('checkEmpty', (value) => {
            if (value.trim() === "") {
                return false;
            }
            return true;
        });

        return () => ValidatorForm.removeValidationRule('checkEmpty');
    }, [])
    useEffect(() => {
        getData()
    }, [searchedSubscriberId]) // eslint-disable-line

    return (
        <div>
            <ValidatorForm ref={form} onSubmit={submitForm}>
                <div className="tw-flex tw-gap-2">
                    <TextValidator label="Subscriber ID" name="subscriberId" value={subscriberId} onChange={e => setSubscriberId(e.target.value)} validators={['matchRegexp:^[0-9]{9}$']} errorMessages={'Subscriber IDs must be all numeric and 9 digits in length.'} size="small" className="sm:tw-w-96" helperText=" " InputProps={{
                        endAdornment: (
                            <>
                            {
                                !(subscriberId.toString().length === 0 || subscriberId.toString().length === 9) ?
                                    <span className="tw-text-xs tw-mt-5 -tw-mr-11 tw-text-slate-500">{subscriberId.toString().length}/9</span> 
                                : null
                            }
                            {
                                subscriberId.trim() !== "" &&<IconButton onClick={() => setSubscriberId("")}>
                                    <IoMdCloseCircle className="tw-text-lg" />
                                </IconButton>
                            }
                            </>
                        )
                    }} />
                    <div>
                        <Button type="submit" variant="contained" color="primary" className="tw-mt-4">Submit</Button>
                    </div>
                </div>
            </ValidatorForm>
            <div>
                { searchedSubscriberId !== "" &&
                    <div>
                        <span className="tw-text-lg tw-font-semibold">Subscriber ID</span>
                        <span className="tw-text-lg tw-font-semibold tw-ml-2 tw-text-sky-600">{searchedSubscriberId}</span>
                        {
                            carereceiver.first_name !== undefined && carereceiver.first_name !== "" &&
                                <span className="tw-text-lg tw-font-semibold tw-ml-2 tw-text-sky-600">({carereceiver.first_name} {carereceiver.last_name})</span>
                        }
                    </div>
                }
                <div className="tw-mt-4">
                {
                    loading
                        ? <DataLoading />
                        // : (assessments.length === 0)
                        //     ? <NoRecord />
                            : searchedSubscriberId.trim().length !== 0 ?
                                <div className="tw-bg-sky-50 tw-border">
                                    <DataGrid rows={assessments} columns={columns} pageSize={rowsPerPage} page={page} onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)} onPageChange={params => setPage(params.page)} autoHeight={true} rowsPerPageOptions={[5, 10, 20]} components={{
                                        NoRowsOverlay: () => <div className="tw-flex tw-justify-center tw-items-center tw-h-full">No Caregivers Found</div>,
                                    }} />
                                    <div className="tw-mt-4 tw-flex tw-gap-2">
                                        <Button variant="contained" color="success" onClick={addNewCaregiver} size="small">
                                            <span>Add Caregiver</span>
                                            <FaUserPlus className="tw-ml-2" />
                                        </Button>
                                        <Button variant="contained" color="warning" onClick={noavailablecaregiver} size="small">
                                            <span>No Available Caregiver</span>
                                            <FaUserClock className="tw-ml-2" />
                                        </Button>
                                    </div>
                                </div>
                            : null
                }
                </div>
            </div>
            <CarereceiverForm open={showCarereceiverForm} carereceiver_id={carereceiver.id ?? 0} acknowledge={true} subscriber_id={searchedSubscriberId} onClose={() => setShowCarereceiverForm(false)} onSuccess={addedNoCarereceiverAvailable} />
            <ReassessmentReason open={showReassessmentReason} reassessment={reassessmentId.case_id !== 0} onClose={() => setShowReassessmentReason(false)} onSubmit={startAssessment} />
            <ActivateCase case_id={alterCaseId} is_active={alterCaseIsActive} open={alterCaseOpen} onClose={() => setAlterCaseOpen(false)} onSuccess={caseAltered} />
        </div>
    )
}

export default SubscriberSearch