import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { CgShapeCircle } from "react-icons/cg"
import { FaCheckCircle } from "react-icons/fa"
import { TextAnimateY } from "../data/anims"
import AssessmentTextField from "./AssessmentTextField"

const AssessmentRadio = ({ question, id, setAnswer, disabled=false }) => {

    const [radioValues, setRadioValues] = useState([])

    const radioSelect = c => {
        if(disabled) return
        if(question.multiple) {
            if(radioValues.includes(c.value)) setRadioValues(radioValues.filter(e => e !== c.value))
            else setRadioValues([...radioValues, c.value])
        } else {
            if(radioValues.length > 0 && radioValues[0] === c.value) setRadioValues([])
            else setRadioValues([c.value])
        }
    }

    useEffect(() => {
        let e = {...question}
        e.value = radioValues
        if(!disabled) setAnswer(id, e)
    }, [radioValues]) // eslint-disable-line
    useEffect(() => {
        setRadioValues(question.value)
    }, []) // eslint-disable-line

    const setChildrenValue = (num, value) => {
        let a = [...question.options[radioValues[0]].children]
        a[num] = value
        let q = [...question.options]
        q[radioValues[0]].children = a
        if(!disabled) setAnswer(id, {...question, options: q, value: radioValues})
    }
    useEffect(() => {
        if(disabled) setRadioValues(question.value)
    }, [question, disabled])

    return (
        <div className="tw-flex tw-flex-col tw-gap-3">
            <div className="tw-flex tw-gap-4 tw-flex-wrap">
                {
                    question.options.map((c, i) => (
                        <div key={i}>
                            <button key={i} className={`tw-border-2 tw-border-gray-300 tw-transition-all tw-duration-300 tw-rounded-lg tw-py-1.5 tw-pl-2 tw-pr-10 tw-flex tw-justify-center tw-items-center tw-gap-2 ${radioValues.includes(c.value) ? 'tw-border-green-600' : ''}`} onClick={() => radioSelect(c)} disabled={disabled}>
                                {
                                    radioValues.includes(c.value) ?
                                        <FaCheckCircle className="tw-text-green-600" />
                                    :
                                        <CgShapeCircle className="tw-text-gray-300" />
                                }
                                <span className="tw-text-gray-600 tw-text-sm">{ c.text }</span>
                            </button>
                        </div>
                    ))
                }
            </div>
            <AnimatePresence>
                {
                    (radioValues.length > 0 && question.options[radioValues[0]].children) ?
                        <motion.div variants={TextAnimateY} initial="hidden" animate="visible" exit="hidden" className="tw-flex tw-flex-wrap tw-gap-6">
                            {
                                question.options[radioValues[0]].children.map((c, i) => (
                                    c.type === "textfield" ?
                                        <AssessmentTextField key={i} question={c} id={i} setAnswer={setChildrenValue} disabled={disabled} />
                                    : null
                                ))
                            }
                        </motion.div>
                    : null
                }
            </AnimatePresence>
        </div>
    )
}

export default AssessmentRadio