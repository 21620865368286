import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMsal } from "@azure/msal-react"
import Loader from "./pages/Loader"
import Paths from "./Paths"
import { loginRequest } from "./authConfig"
import API from "./axios/API"
import { setDetails, setIsLoggedIn } from "./store/auth/reducers"
import { LinearProgress } from "@mui/material"
import { debounce } from "./data/commons"

const Root = () => {

    const [isCheckedLogin, setIsCheckedLogin] = useState(false)
    const [loginMsg, setLoginMsg] = useState('Logging you In, please wait...')
    const [isLoading, setIsLoading] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [lastVerifiedTime, setLastVerifiedTime] = useState(0)
    const { isLoggedIn } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const { instance, accounts } = useMsal();

    const loginUser = () => {
        instance.loginRedirect(loginRequest)
            .then((res) => {
            })
            .catch(e => {
                console.log(e);
            })
    }
    const verifyUser = useCallback(debounce((token) => { // eslint-disable-line
        const date = new Date()
        if(date.getTime() - lastVerifiedTime > 1000) {
            API.post('/verify-user', { token: token })
                .then((res) => {
                    if (res.data.status) {
                        localStorage.setItem('token', res.data.token)
                        setLoggedIn(true)
                    } else {
                        console.log('err', res.data.message);
                    }
                    setIsCheckedLogin(true)
                })
                .catch((err) => {
                    console.log('err', err);
                    setIsCheckedLogin(true)
                })
            setLastVerifiedTime(date.getTime())
        }
    }, 200), [lastVerifiedTime])

    useEffect(() => {
        if(accounts.length > 0) {
            let r = {...loginRequest}
            r.account = instance.getAccountByHomeId(accounts[0].homeAccountId)
            instance.acquireTokenSilent(r)
                .then((s) => {
                    verifyUser(s.accessToken)
                })
                .catch(err => {
                    console.log('err', err);
                    loginUser()
                })
        } else {
            loginUser()
        }
    }, [accounts]) // eslint-disable-line
    useEffect(() => {
        if (isCheckedLogin) {
            if(loggedIn) {
                let token = localStorage.getItem('token')
                if(token) {
                    let tokenSplit = token.split('.')
                    let decoded = JSON.parse(atob(tokenSplit[1]))
                    dispatch(setDetails(decoded))
                    dispatch(setIsLoggedIn(true))
                }
            } else {
                loginUser()
            }
        }
    }, [isCheckedLogin, loggedIn]) // eslint-disable-line
    useEffect(() => {
        // if(isCheckedLogin) {
        //     setLoggedIn(isLoggedIn)
        // }
        if(isCheckedLogin && !isLoggedIn) {
            localStorage.removeItem('token')
            localStorage.removeItem('ad-token')
            setLoginMsg("Logging you out, please wait...")
            instance.logoutRedirect({
                // postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
        }
    }, [isLoggedIn]) // eslint-disable-line
    useEffect(() => {
        // adding interceptors to api
        API.interceptors.request.use(request => {
            request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            setIsLoading(true)
            return request
        })
        API.interceptors.response.use(response => {
            if(response.status)
                setIsLoading(false)
                return response
            }, error => {
                setIsLoading(false)
                if (error.response && error.response.status === 401) {
                    // dispatch(setAlert({ title: t('unauthorized'), subtitle: t('Unauthorized to proceed'), active: true, type: 'error', force: true }))
                    dispatch(setIsLoggedIn(false))
                }
                return Promise.reject(error)
            }
        )
    }, []) // eslint-disable-line

    useLayoutEffect(() => {
        instance.handleRedirectPromise()
            .then((s) => {
                if (s !== null && s.account != null) {
                    //  currentAccount.setCurrentAccount(s.account.username, s.account.homeAccountId, s.account.tenantId);
                    localStorage.setItem('ad-token', `${s.accessToken}`)
                    setTimeout(() => {
                        verifyUser(s.accessToken)
                    }, 1000)
                }
            })
            .catch((err) => {
                console.log('err', err);
                setIsCheckedLogin(true)
            });
    }, [instance]); // eslint-disable-line

    return (
        <div>
            { (isLoading && isLoggedIn) &&
                <div className="tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-30">
                    <LinearProgress />
                </div>
            }
            {
                (!isLoggedIn || !isCheckedLogin)
                    ? <Loader showLoginMsg={true} loginMsg={loginMsg} />
                    : <Paths />
            }
        </div>
    )
}

export default Root