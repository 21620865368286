import { HiDocumentAdd, HiUser, HiHome } from "react-icons/hi"
import { FaUsersCog, FaUserShield } from "react-icons/fa"
import { AiTwotoneReconciliation } from "react-icons/ai"

export const menu = [
    {
        name: 'Home',
        path: '/',
        icon: HiHome,
    },
    {
        name: 'Assessment',
        path: '/new-assessment',
        icon: HiDocumentAdd,
        ignoreMobile: true,
    },
    {
        name: 'Cases',
        path: '/cases',
        icon: AiTwotoneReconciliation,
    },
    {
        name: 'Care Receivers',
        path: '/carereceivers',
        icon: HiUser,
    },
    {
        name: 'Caregivers',
        path: '/caregivers',
        icon: FaUserShield,
    },
    {
        name: 'Users',
        path: '/users',
        icon: FaUsersCog,
        notRoles: [1]
    },
]

export const reassessmentReason = [
    {
        text: 'New Eligible Enrollee',
        value: 1,
    },
    {
        text: 'Annual',
        value: 2,
    },
    {
        text: 'Change in Condition',
        value: 3,
    },
    {
        text: 'Transition',
        value: 4,
    },
    {
        text: 'New Eligible',
        value: 5,
    },
    {
        text: 'New Referral',
        value: 6,
    },
    {
        text: 'Potential Transition',
        value: 7,
    },
    {
        text: 'Short term Stays',
        value: 8,
    },
]

export const relationshipOptions = [
    {value: 1, text: 'Wife'},
    {value: 2, text: 'Husband'},
    {value: 3, text: 'Domestic Partner'},
    {value: 4, text: 'Mother'},
    {value: 5, text: 'Father'},
    {value: 6, text: 'Mother-in-law'},
    {value: 7, text: 'Father-in-law'},
    {value: 8, text: 'Grandmother'},
    {value: 9, text: 'Grandfather'},
    {value: 10, text: 'Brother'},
    {value: 11, text: 'Sister'},
    {value: 12, text: 'Son'},
    {value: 13, text: 'Daughter'},
    {value: 14, text: 'Other'}
]

export const stateOptions = [
    {
        "value": "AL",
        "text": "Alabama (AL)"
    },
    {
        "value": "AK",
        "text": "Alaska (AK)"
    },
    {
        "value": "AZ",
        "text": "Arizona (AZ)"
    },
    {
        "value": "AR",
        "text": "Arkansas (AR)"
    },
    {
        "value": "AS",
        "text": "American Samoa (AS)"
    },
    {
        "value": "CA",
        "text": "California (CA)"
    },
    {
        "value": "CO",
        "text": "Colorado (CO)"
    },
    {
        "value": "CT",
        "text": "Connecticut (CT)"
    },
    {
        "value": "DE",
        "text": "Delaware (DE)"
    },
    {
        "value": "DC",
        "text": "District of Columbia (DC)"
    },
    {
        "value": "FL",
        "text": "Florida (FL)"
    },
    {
        "value": "GA",
        "text": "Georgia (GA)"
    },
    {
        "value": "GU",
        "text": "Guam (GU)"
    },
    {
        "value": "HI",
        "text": "Hawaii (HI)"
    },
    {
        "value": "ID",
        "text": "Idaho (ID)"
    },
    {
        "value": "IL",
        "text": "Illinois (IL)"
    },
    {
        "value": "IN",
        "text": "Indiana (IN)"
    },
    {
        "value": "IA",
        "text": "Iowa (IA)"
    },
    {
        "value": "KS",
        "text": "Kansas (KS)"
    },
    {
        "value": "KY",
        "text": "Kentucky (KY)"
    },
    {
        "value": "LA",
        "text": "Louisiana (LA)"
    },
    {
        "value": "ME",
        "text": "Maine (ME)"
    },
    {
        "value": "MD",
        "text": "Maryland (MD)"
    },
    {
        "value": "MA",
        "text": "Massachusetts (MA)"
    },
    {
        "value": "MI",
        "text": "Michigan (MI)"
    },
    {
        "value": "MN",
        "text": "Minnesota (MN)"
    },
    {
        "value": "MS",
        "text": "Mississippi (MS)"
    },
    {
        "value": "MO",
        "text": "Missouri (MO)"
    },
    {
        "value": "MT",
        "text": "Montana (MT)"
    },
    {
        "value": "NE",
        "text": "Nebraska (NE)"
    },
    {
        "value": "NV",
        "text": "Nevada (NV)"
    },
    {
        "value": "NH",
        "text": "New Hampshire (NH)"
    },
    {
        "value": "NJ",
        "text": "New Jersey (NJ)"
    },
    {
        "value": "NM",
        "text": "New Mexico (NM)"
    },
    {
        "value": "NY",
        "text": "New York (NY)"
    },
    {
        "value": "MP",
        "text": "Northern Mariana Islands (MP)"
    },
    {
        "value": "NC",
        "text": "North Carolina (NC)"
    },
    {
        "value": "ND",
        "text": "North Dakota (ND)"
    },
    {
        "value": "OH",
        "text": "Ohio (OH)"
    },
    {
        "value": "OK",
        "text": "Oklahoma (OK)"
    },
    {
        "value": "OR",
        "text": "Oregon (OR)"
    },
    {
        "value": "PA",
        "text": "Pennsylvania (PA)"
    },
    {
        "value": "PR",
        "text": "Puerto Rico (PR)"
    },
    {
        "value": "RI",
        "text": "Rhode Island (RI)"
    },
    {
        "value": "SC",
        "text": "South Carolina (SC)"
    },
    {
        "value": "SD",
        "text": "South Dakota (SD)"
    },
    {
        "value": "TN",
        "text": "Tennessee (TN)"
    },
    {
        "value": "TX",
        "text": "Texas (TX)"
    },
    {
        "value": "UT",
        "text": "Utah (UT)"
    },
    {
        "value": "VT",
        "text": "Vermont (VT)"
    },
    {
        "value": "VA",
        "text": "Virginia (VA)"
    },
    {
        "value": "VI",
        "text": "Virgin Islands (VI)"
    },
    {
        "value": "WA",
        "text": "Washington (WA)"
    },
    {
        "value": "WV",
        "text": "West Virginia (WV)"
    },
    {
        "value": "WI",
        "text": "Wisconsin (WI)"
    },
    {
        "value": "WY",
        "text": "Wyoming (WY)"
    }
]