const BottomWave = () => {

    return(
        <div className="tw-fixed tw-bottom-0 tw-right-0 tw-left-0 tw-opacity-50 tw-z-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" fillOpacity="1" d="M0,320L120,314.7C240,309,480,299,720,256C960,213,1200,139,1320,101.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" fill-opacity="1" d="M0,320L80,288C160,256,320,192,480,186.7C640,181,800,235,960,261.3C1120,288,1280,288,1360,288L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> */}
        </div>
    )
}

export default BottomWave