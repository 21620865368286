import { Button, IconButton, InputAdornment, TableCell, TableRow, TextField, ThemeProvider, Tooltip } from "@mui/material"
import { MdPersonSearch } from "react-icons/md"
import DataLoading from "../components/DataLoading"
import NoRecord from "../components/NoRecord"
import API from "../axios/API"
import Moment from "react-moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { debounce, getRelationship, getState, getReassessmentReason } from "../data/commons"
import AssociatedCarereceiverCases from "../components/AssociatedCarereceiverCases"
import CarereceiverForm from "../components/CarereceiverForm"
import { IoMdCloseCircle } from "react-icons/io"
import MUIDataTable from "mui-datatables"
import { getMuiTheme, headCellProp } from "../data/datatabletheme"
import { IoMdRefresh } from "react-icons/io"
import { NavLink, useNavigate } from "react-router-dom"
import ReassessmentReason from "../components/ReassessmentReason"
import { HiClipboard } from "react-icons/hi"

const Carereceivers = () => {

    const [search, setSearch] = useState("")
    const [rows, setRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [initialDataFilled, setInitialDataFilled] = useState(false)
    const [editCarereceiverId, setEditCarereceiverId] = useState(0)
    const [showAssociatedCarereceiverCases, setShowAssociatedCarereceiverCases] = useState(false)
    const [showCarereceiverForm, setShowCarereceiverForm] = useState(false)
    const [expandedRows, setExpandedRows] = useState([])
    const [carereceiverCaseDetails, setCarereceiverCaseDetails] = useState({})
    const [gettingCaseDetails, setGettingCaseDetails] = useState(false)
    const [showReassessment, setShowReassessment] = useState(false)
    const [reassessmentDetails, setReassessmentDetails] = useState({case_id: 0, carereceiver_id: 0, subscriber_id: 0, reason: ''})

    const navigate = useNavigate()

    const reassess = (carereceiver_id, case_id, subscriber_id) => {
        console.log({case_id, carereceiver_id, subscriber_id, reason: ''})
        setReassessmentDetails({case_id, carereceiver_id, subscriber_id, reason: ''})
        setShowReassessment(true)
    }
    const startAssessment = (reason) => {
        setShowReassessment(false)
        navigate('/new-assessment', {state: {initial: {case_id: reassessmentDetails.case_id, subscriber_id: reassessmentDetails.subscriber_id, reason, carereceiver_id: reassessmentDetails.carereceiver_id}, reassess: true}})
    }

    const columns = [
        { name: "id", label: "ID", options: {
            setCellHeaderProps: headCellProp
        }},
        { name: "name", label: "Name",  valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`, options: {
            customBodyRenderLite: (index) => {
                return (
                    <div>{filteredRows[index].first_name} {filteredRows[index].last_name}</div>
                );
            }, setCellHeaderProps: headCellProp
        }},
        { name: "subscriber_id", label: "Subscriber ID", options: {
            setCellHeaderProps: headCellProp
        }},
        { name: "phone", label: "Phone", options: {
            setCellHeaderProps: headCellProp
        }},
        { name: "address", label: "Address", options: {
            display: false,
            setCellHeaderProps: headCellProp
        }},
        { name: "city", label: "City", options: {
            display: false,
            setCellHeaderProps: headCellProp
        }},
        { name: "state", label: "State", options: {
            display: false,
            setCellHeaderProps: headCellProp,
            customBodyRenderLite: (index) => {
                return (
                    <div>{getState(filteredRows[index].state).text}</div>
                );
            }
        }},
        { name: "zip", label: "Zip", options: {
            display: false,
            setCellHeaderProps: headCellProp
        }},
        { name: "created_by", label: "Created By", options: {
            setCellHeaderProps: headCellProp
        }},
        { name: "updated_by", label: "Updated By", options: {
            display: false,
            setCellHeaderProps: headCellProp
        }},
        { name: "created_at", label: "Created At", options: {
            setCellHeaderProps: headCellProp,
            customBodyRenderLite: (index) => <Moment format="MMM, DD YYYY hh:mm a">{filteredRows[index].created_at}</Moment>
        }},
        { name: "updated_at", label: "Updated At", options: {
            display: false,
            setCellHeaderProps: headCellProp,
            customBodyRenderLite: (index) => <Moment format="MMM, DD YYYY hh:mm a">{filteredRows[index].updated_at}</Moment>
        }},
        { name: "", label: "", options: {
            setCellHeaderProps: headCellProp,
            customBodyRenderLite: (index) => (
                <div className="tw-flex tw-gap-2 tw-justify-center">
                    <Button size="small" variant="contained" color="primary" onClick={() => associatedCases(filteredRows[index].id)}>Cases</Button>
                    <Button size="small" variant="contained" color="warning" onClick={() => editCarereceiver(filteredRows[index].id)}>Edit</Button>
                </div>
            )
        }}
    ]

    const isCaseActiveCell = (val, index) => {
        if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return {}
        let i = carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index]
        if(!i.is_active) return {
            style: {
                backgroundColor: '#ffe6e6'
            }
        }
        return {}
    }

    const caseColumns = [
        { name: "caregiver", label: "Caregiver", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
            customBodyRenderLite: (index) => {
                if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return <div></div>
                let i = carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index]
                return (
                    i !== undefined && <div>{i.cg_id === "" ? "No Caregiver Available" : i.caregiver}</div>
                )
            }
        }},
        { name: "cg_phone", label: "Phone", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
        }},
        { name: "cg_email", label: "Email", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
        }},
        { name: "relationship", label: "Relationship", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
            customBodyRenderLite: (index) => {
                if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return <div></div>
                return (
                    <div>{getRelationship(carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index].relationship_id).text}</div>
                )
            }
        }},
        { name: "reason_id", label: "Reason", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
            customBodyRenderLite: (index) => {
                if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return <div></div>
                return (
                    <div>{getReassessmentReason(carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index].reason_id).text}</div>
                )
            }
        }},
        { name: "created_by", label: "Coordinator", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
        }},
        { name: "created_at", label: "Created At", options: {
            setCellHeaderProps: headCellProp,
            setCellProps: isCaseActiveCell,
            customBodyRenderLite: (index) => {
                if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return <div></div>
                return <Moment format="MMM, DD YYYY hh:mm a">{carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index].created_at}</Moment>
            }
        }},
        {
            name: "", label: "", options: {
                setCellHeaderProps: headCellProp,
                setCellProps: isCaseActiveCell,
                customBodyRenderLite: (index) => {
                    if(expandedRows.length === 0 || filteredRows[expandedRows[0]] === undefined || carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index] === undefined) return <div></div>
                    let i = carereceiverCaseDetails[filteredRows[expandedRows[0]].id][index]
                    return (
                        i !== undefined && <div>
                            {i.cg_id !== "" &&
                                <Tooltip title="View Assessment">
                                    <NavLink to={`/assessment/${i.assessment_id}`}>
                                        <IconButton type="button" size="" variant="contained" color="primary">
                                            <HiClipboard />
                                        </IconButton>
                                    </NavLink>
                                </Tooltip>
                            }
                            {
                                Math.max(...carereceiverCaseDetails[filteredRows[expandedRows[0]].id].filter(c => c.case_id === i.case_id && c.cg_id !== "" && i.is_active).map(c => parseInt(c.assessment_id))).toString() === i.assessment_id.toString() && <Tooltip title="Rescreen">
                                    <IconButton size="" variant="contained" color="primary" onClick={() => reassess(filteredRows[expandedRows[0]].id, i.case_id, filteredRows[expandedRows[0]].subscriber_id)}>
                                        <IoMdRefresh />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    )
            }}
        }
    ]

    const getCarereceivers = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get("/carereceivers")
            .then(res => {
                setRows(res.data)
                setInitialDataFilled(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setInitialDataFilled(true)
            })
    }, 200), [])
    const associatedCases = (id) => {
        setEditCarereceiverId(id)
        setShowAssociatedCarereceiverCases(true)
    }
    const editCarereceiver = (id) => {
        setEditCarereceiverId(id)
        setShowCarereceiverForm(true)
    }
    const carereceiverEdited = () => {
        setShowCarereceiverForm(false)
        getCarereceivers()
    }
    
    useEffect(() => {
        getCarereceivers()
    }, []) // eslint-disable-line
    useEffect(() => {
        if(search.trim() === "") setFilteredRows(rows)
        else setFilteredRows(rows.filter(row => Object.keys(row).some(key => row[key].toString().toLowerCase().includes(search.toLowerCase()))))
        if(initialDataFilled) setLoading(false)
    }, [search, rows]) // eslint-disable-line

    const caseOptions = useMemo(() => ({
        download: false,
        elevation: 2,
        expandableRows: false,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        resizableColumns: false,
        rowHover: true,
        search: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "standard",
    }), [])

    const options = useMemo(() => ({
        download: false,
        elevation: 2,
        expandableRows: true,
        expandableRowsHeader: false,
        filter: false,
        print: false,
        resizableColumns: false,
        rowHover: true,
        search: false,
        selectableRows: "none",
        responsive: "standard",
        rowsExpanded: expandedRows,
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length + 1}>
                        <div className="tw-flex tw-flex-col tw-gap-2 -tw-m-4 tw-py-2 tw-px-4">
                        {
                            gettingCaseDetails 
                                ? <DataLoading /> :
                                <div className="tw-bg-blue-100/60">
                                    <ThemeProvider theme={getMuiTheme()}>
                                        {
                                            carereceiverCaseDetails[rowData[0]] !== undefined && carereceiverCaseDetails[rowData[0]].length > 0 &&
                                            <MUIDataTable title="" data={carereceiverCaseDetails[rowData[0]]} columns={caseColumns} options={caseOptions} />
                                        }
                                    </ThemeProvider>
                                </div>

                        }
                        </div>
                    </TableCell>
                </TableRow>
            )
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => setExpandedRows(curExpanded.map(c => c.dataIndex).filter(c => c !== undefined).filter(c => !expandedRows.includes(c))),
    }), [expandedRows, gettingCaseDetails, carereceiverCaseDetails]) // eslint-disable-line

    useEffect(() => {
        if(expandedRows.length > 0 && carereceiverCaseDetails[filteredRows[expandedRows[0]].id] === undefined) {
            setGettingCaseDetails(true)
            const row = filteredRows[expandedRows[0]]
            API.post(`/get-assessment-by-carereceiver-id`, {carereceiver_id: row.id})
                .then(res => {
                    setCarereceiverCaseDetails({...carereceiverCaseDetails, [row.id]: res.data})
                    setGettingCaseDetails(false)
                })
                .catch(err => {
                    setCarereceiverCaseDetails({...carereceiverCaseDetails, [row.id]: []})
                    console.log(err)
                    setGettingCaseDetails(false)
                }
            )
        }
    }, [expandedRows, carereceiverCaseDetails, filteredRows])

    return (
        <div className="tw-w-full tw-mt-4 tw-mb-12 tw-pr-4">
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-items-end sm:tw-justify-between">
                <div className="tw-font-bold tw-text-xl tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-inline-block tw-bg-clip-text tw-text-transparent">Care Receivers</div>
                <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                    <TextField value={search} onChange={ev => setSearch(ev.target.value)} className="md:tw-w-96" label="Search" size="small" InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdPersonSearch className="tw-text-2xl" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            search.trim() !== "" &&<IconButton onClick={() => setSearch("")}>
                                <IoMdCloseCircle className="tw-text-lg" />
                            </IconButton>
                        )
                    }} />
                </div>
            </div>
            <div className="tw-mt-4">
                {
                    loading
                        ? <DataLoading />
                        : (filteredRows.length === 0)
                            ? <NoRecord />
                            : <div className="tw-bg-sky-50 tw-border">
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable title="" data={filteredRows} columns={columns} options={options} />
                                </ThemeProvider>
                            </div>
                }
            </div>
            <AssociatedCarereceiverCases open={showAssociatedCarereceiverCases} onClose={() => setShowAssociatedCarereceiverCases(false)} carereceiver_id={editCarereceiverId} />
            <CarereceiverForm open={showCarereceiverForm} acknowledge={false} onClose={() => setShowCarereceiverForm(false)} carereceiver_id={editCarereceiverId} onSuccess={carereceiverEdited} />
            <ReassessmentReason open={showReassessment} onClose={() => setShowReassessment(false)} onSubmit={startAssessment} />
        </div>
    )
}

export default Carereceivers