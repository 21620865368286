import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { useDispatch } from "react-redux"
import DataLoading from "./DataLoading"
import { setAlert } from "../store/auth/reducers"
import API from "../axios/API"
import { stateOptions } from "../data/menu"
import { getState } from "../data/commons"

const CarereceiverForm = ({ open, carereceiver_id, acknowledge=false, subscriber_id, onClose, onSuccess }) => {

    const emptyCarereceiver = {
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        subscriber_id: ""
    }
    const form = useRef(null)
    const [carereceiver, setCarereceiver] = useState({...emptyCarereceiver})
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))
    const dispatch = useDispatch()

    useEffect(() => {
        ValidatorForm.addValidationRule('checkEmpty', (value) => {
            if (value.trim() === "") {
                return false;
            }
            return true;
        });

        return () => ValidatorForm.removeValidationRule('checkEmpty');
    }, [])

    const handleChange = (ev) => {
        setCarereceiver({ ...carereceiver, [ev.target.name]: ev.target.value })
    }
    const resetForm = () => {
        setCarereceiver({...emptyCarereceiver})
    }
    const validateForm = () => {
        form.current.submit()
    }
    const submitForm = () => {
        setSubmitLoading(true)
        if(acknowledge) {
            API.post(`/acknowledge-no-caregiver`, carereceiver)
                .then(res => {
                    dispatch(setAlert({ open: true, message: "Acknowledged", severity: "success" }))
                    setSubmitLoading(false)
                    onSuccess()
                })
                .catch(err => {
                    dispatch(setAlert({ open: true, message: "Acknowledgement Failed", severity: "error" }))
                    setSubmitLoading(false)
                })
        } else {
            API.put(`/carereceiver/${carereceiver_id}`, carereceiver)
                .then(res => {
                    dispatch(setAlert({ open: true, message: "Care Receiver updated", severity: "success" }))
                    onSuccess()
                    setSubmitLoading(false)
                })
                .catch(err => {
                    dispatch(setAlert({ open: true, message: "Error updating carereceiver", severity: "error" }))
                    setSubmitLoading(false)
                })
        }
    }
    useEffect(() => {
        if(!open) {
            resetForm()
        } else if (carereceiver_id !== 0 && open) {
            setLoading(true)
            API.get(`/carereceiver/${carereceiver_id}`)
                .then(res => {
                    setCarereceiver(res.data)
                    setLoading(false)
                })
                .catch(err => {
                    dispatch(setAlert({ open: true, message: "Error loading carereceiver", severity: "error" }))
                    setLoading(false)
                })
        } else if(acknowledge) {
            setCarereceiver({ ...carereceiver, subscriber_id: subscriber_id })
        }
    }, [carereceiver_id, open]) // eslint-disable-line

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">{acknowledge ? "No Caregiver Available" : carereceiver_id === 0 ? "Add User" : "Edit Care Receiver"}</span>
            </DialogTitle>
            <DialogContent>
                { loading ? <DataLoading /> :
                    <ValidatorForm ref={form} onSubmit={submitForm}>
                        <div className="tw-py-4 tw-flex tw-flex-col tw-gap-1 tw-items-center">
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="First Name" name="first_name" value={carereceiver.first_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" autoFocus helperText=" " size="small" />
                                <TextValidator label="Last Name" name="last_name" value={carereceiver.last_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Subscriber ID" name="subscriber_id" value={carereceiver.subscriber_id} onChange={handleChange} validators={["checkEmpty", "matchRegexp:^[0-9]{9}$"]} errorMessages={["This field is required", "Subscriber ID is not valid"]} className="tw-w-64" helperText=" " size="small" disabled={true} />
                                <TextValidator label="Phone" name="phone" value={carereceiver.phone} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Address" name="address" value={carereceiver.address} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                                <TextValidator label="City" name="city" value={carereceiver.city} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                {/* <TextValidator label="State" name="state" value={carereceiver.state} onChange={handleChange} className="tw-w-64" size="small" /> */}
                                <Autocomplete options={stateOptions} getOptionLabel={(option) => option.text} value={{text: getState(carereceiver.state).text, value: carereceiver.state}} onChange={(ev, value) => setCarereceiver({ ...carereceiver, state: value?.value || '' })} renderInput={(params) => <TextField {...params} label="State" className="tw-w-64" size="small" />} className="tw-w-64 tw-mb-4" />
                                <TextValidator label="Zip" name="zip" value={carereceiver.zip} onChange={handleChange} validators={['matchRegexp:^[0-9]{5}$']} errorMessages={'Zip must have 5 digits'} className="tw-w-64" helperText=" " size="small" />
                            </div>
                        </div>
                    </ValidatorForm>
                }
            </DialogContent>
            <DialogActions>
                { !loading &&
                    <>
                        <Button variant="outlined" color="error" disabled={submitLoading} loading onClick={onClose}>Cancel</Button>
                        <Button variant="contained" color="success" disabled={submitLoading} onClick={validateForm}>{ acknowledge ? 'Acknowledge' : 'Submit' }</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}

export default CarereceiverForm