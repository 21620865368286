import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import API from "../axios/API"
import { setAlert } from "../store/auth/reducers"

const DeleteUser = ({ open, user_id, name, onClose, onSuccess }) => {

    const [loading, setLoading] = useState(false)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))
    const dispatch = useDispatch()

    const deleteUser = () => {
        setLoading(true)
        API.delete(`/user/${user_id}`)
        .then(res => {
            dispatch(setAlert({open: true, message: "User deleted successfully", severity: "success"}))
            setLoading(false)
            onSuccess()
        })
        .catch(err => {
            dispatch(setAlert({open: true, message: "Something went wrong", severity: "error"}))
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">Delete User</span>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to remove <span className="tw-text-lg">"{name}"</span>?
            </DialogContent>
            <DialogActions>
                <Button color="success" variant="outlined" disabled={loading} onClick={onClose}>No, Cancel</Button>
                <Button color="error" variant="contained" disabled={loading} onClick={deleteUser}>Yes, delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteUser