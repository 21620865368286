import { Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { menu } from "../data/menu"

const BottomNavigation = () => {

    const { authDetails } = useSelector(state => state.auth)

    return (
        <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-h-14 tw-flex tw-justify-around tw-bg-sky-300 tw-pt-1 sm:tw-hidden tw-z-10">
            {
                menu.filter(c => !c.ignoreMobile).filter(c => c.notRoles === undefined || !c.notRoles.includes(authDetails.role)).map(item => (
                    <Tooltip key={item.name} title={item.name} placement="top" className="tw-flex-1">
                        <NavLink to={item.path}>
                            {({ isActive }) => (
                                <span className="tw-flex tw-flex-col tw-items-center">
                                    <item.icon className={`tw-text-[35px] tw-transition-all tw-duration-500 ${isActive ? 'tw-text-sky-600' : 'tw-text-slate-600'} tw-p-1 `} />
                                    <span className={`tw-text-[10px] -tw-mt-1 tw-inline-block ${isActive ? 'tw-text-sky-900' : 'tw-text-slate-900'}`}>{ item.name } { isActive }</span>
                                </span>
                            )}
                        </NavLink>
                    </Tooltip>
                ))
            }
        </div>
    )
}

export default BottomNavigation