import { createSlice } from '@reduxjs/toolkit'
const { authState } = require("./store");

const authReducer = createSlice({
    name: 'auth',
    initialState: authState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        },
        setDetails: (state, action) => {
            state.authDetails = action.payload
        },
        setAlert: (state, action) => {
            state.alert = action.payload
        }
    }
})

export const { setIsLoggedIn, setDetails, setAlert } = authReducer.actions
export default authReducer.reducer