import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { debounce } from "../data/commons"
import DataLoading from "./DataLoading"
import API from "../axios/API"
import Assessment from "./Assessment"

const ReadonlyAssessment = () => {

    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [assessment, setAssessment] = useState({})

    const getAssessment = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get(`/assessment/${id}`)
            .then(res => {
                setAssessment(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, 200), [])

    useEffect(() => {
        getAssessment()
    }, []) // eslint-disable-line

    return (
        <div className="tw-h-full tw-py-4">
            {
                loading ? <DataLoading />
                : <Assessment onEditDemographics={() => {}} onSubmitAssessment={() => {}} initialValues={assessment.assessment} readonly={true}  />
            }
        </div>
    )
}

export default ReadonlyAssessment