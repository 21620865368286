import { Button, IconButton, Tooltip } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useCallback, useEffect, useState } from "react"
import Moment from "react-moment"
import { useDispatch } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import API from "../axios/API"
import ActivateCase from "../components/ActivateCase"
import CaregiverForm from "../components/CaregiverForm"
import CarereceiverForm from "../components/CarereceiverForm"
import DataLoading from "../components/DataLoading"
import ReassessmentReason from "../components/ReassessmentReason"
import { debounce, getReassessmentReason, getRelationship, getState } from "../data/commons"
import { setAlert } from "../store/auth/reducers"
import { BsFilePdfFill } from "react-icons/bs"

const CaseDetails = () => {

    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [caregiver, setCaregiver] = useState({})
    const [carereceiver, setCarereceiver] = useState({})
    const [caseData, setCaseData] = useState({})
    const [assessments, setAssessments] = useState([])
    const [alterCaseOpen, setAlterCaseOpen] = useState(false)
    const [showCaregiverForm, setShowCaregiverForm] = useState(false)
    const [showCarereceiverForm, setShowCarereceiverForm] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showReassessment, setShowReassessment] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const getCaseDetails = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get(`/case/${id}`)
            .then(res => {
                setCaregiver(res.data.caregiver || {})
                setCarereceiver(res.data.carereceiver)
                setCaseData(res.data.case)
                setAssessments(res.data.assessments)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                dispatch(setAlert({open: true, message: 'Unable to get case details', severity: 'error'}))
            })
    }, 200), [])
    const startAlterCase = () => {
        setAlterCaseOpen(true)
    }
    const caseAltered = () => {
        setAlterCaseOpen(false)
        getCaseDetails()
    }
    const editCaregiver = () => {
        setShowCaregiverForm(true)
    }
    const editCarereceiver = () => {
        setShowCarereceiverForm(true)
    }
    const edited = () => {
        setShowCaregiverForm(false)
        setShowCarereceiverForm(false)
        getCaseDetails()
    }
    const reassess = () => {
        setShowReassessment(true)
    }
    const startAssessment = (reason) => {
        setShowReassessment(false)
        navigate('/new-assessment', {state: {initial: {case_id: id, subscriber_id: carereceiver.subscriber_id, reason, carereceiver_id: carereceiver.id}, reassess: true}})
    }
    const columns = [
        { field: "id", headerName: "ID", minWidth: 100, flex:1 },
        { field: "reason", headerName: "Reason ID", minWidth: 200, flex:1, valueGetter: (params) => getReassessmentReason(params.row.reason).text },
        { field: "created_by", headerName: "Created By", minWidth: 200, flex:1},
        { field: "created_at", headerName: "Created At", minWidth: 200, flex:1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.created_at}</Moment> },
        { field: "action", headerName: "Action", minWidth: 200, flex:1, renderCell: (params) => (
            caregiver.id && <div className="tw-flex tw-gap-2 tw-justify-center tw-items-center">
                <NavLink to={`/assessment/${params.row.id}`}>
                    <Button type="button" size="small" variant="contained" color="primary">View</Button>
                </NavLink>
                <Tooltip title="Download PDF">
                    <IconButton type="button" size="small" variant="contained" color="warning">
                        <BsFilePdfFill className="tw-text-3xl" />
                    </IconButton>
                </Tooltip>
            </div>
        )}
    ]

    useEffect(() => {
        getCaseDetails()
    }, []) // eslint-disable-line

    return (
        <div className="tw-w-full tw-mt-4 tw-pr-4">
            <div className="tw-flex tw-flex-1 tw-flex-row tw-gap-3 tw-items-end sm:tw-gap-4">
                <div className="tw-font-bold tw-text-xl tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-inline-block tw-bg-clip-text tw-text-transparent">Case Details</div>
                { !loading && <Button type="button" size="small" variant="contained" color={caseData.is_active ? 'error' : 'success'} onClick={() => startAlterCase()}>{caseData.is_active ? 'Deactivate' : 'Activate'}</Button> }
            </div>
            {
                loading
                ? <DataLoading />
                : 
                    <div className="tw-w-full tw-flex tw-flex-col tw-pb-8">
                        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full">
                            <div className="tw-flex-1">
                                <div className="tw-font-bold tw-text-base tw-mt-4">
                                    <span className="tw-mr-2">Caregiver</span>
                                    {caregiver.id && <Button type="button" size="small" variant="contained" color="primary" onClick={editCaregiver}>Edit</Button>}
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">First Name</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{caregiver.first_name}</div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Last Name</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{caregiver.last_name}</div>
                                    </div>
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Email</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{caregiver.email}</div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Phone</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{caregiver.phone}</div>
                                    </div>
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Address</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">
                                            {caregiver.address}{caregiver.address && caregiver.city && ', '}{caregiver.city}{caregiver.city && caregiver.state && ', '}{getState(caregiver.state).text}{caregiver.state && caregiver.zip && ' '}{caregiver.zip}
                                        </div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Relationship</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{getRelationship(caseData.relationship_id).text}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="tw-flex-1">
                                <div className="tw-font-bold tw-text-base tw-mt-4">
                                    <span className="tw-mr-2">Care Receiver</span>
                                    <Button type="button" size="small" variant="contained" color="primary" onClick={editCarereceiver}>Edit</Button>
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">First Name</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{carereceiver.first_name}</div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Last Name</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{carereceiver.last_name}</div>
                                    </div>
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Subscriber ID</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{carereceiver.subscriber_id}</div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Phone</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">{carereceiver.phone}</div>
                                    </div>
                                </div>
                                <div className="tw-flex">
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        <div className="tw-text-xs sm:tw-text-sm tw-mt-4">Address</div>
                                        <div className="tw-mt-4 tw-font-bold tw-text-sm tw-text-sky-600">
                                            {carereceiver.address}{carereceiver.address && carereceiver.city && ', '}{carereceiver.city}{carereceiver.city && carereceiver.state && ', '}{getState(carereceiver.state).text}{carereceiver.state && carereceiver.zip && ' '}{carereceiver.zip}
                                        </div>
                                    </div>
                                    <div className="tw-flex tw-flex-1 tw-pl-2 tw-gap-3 tw-items-center sm:tw-gap-4">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-mt-10">
                            <div className="tw-flex-1">
                                <div className="tw-font-bold tw-text-base tw-mt-4">
                                    <span className="tw-mr-2">Assessments</span>
                                    { (caseData.is_active && caregiver.id) && <Button type="button" size="small" variant="contained" color="primary" onClick={reassess}>Reassess</Button> }
                                </div>
                                <div className="tw-mt-4 tw-bg-sky-100">
                                    <DataGrid rows={assessments} columns={columns} pageSize={rowsPerPage} page={page} onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)} onPageChange={params => setPage(params.page)} autoHeight={true} rowsPerPageOptions={[5, 10, 20]} />
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <ActivateCase case_id={id} is_active={caseData.is_active} open={alterCaseOpen} onClose={() => setAlterCaseOpen(false)} onSuccess={caseAltered} />
            <CaregiverForm open={showCaregiverForm} onClose={() => setShowCaregiverForm(false)} caregiver_id={caregiver.id} onSuccess={edited} />
            <CarereceiverForm open={showCarereceiverForm} acknowledge={false} onClose={() => setShowCarereceiverForm(false)} carereceiver_id={carereceiver.id} onSuccess={edited} />
            <ReassessmentReason open={showReassessment} onClose={() => setShowReassessment(false)} onSubmit={startAssessment} />
        </div>
    )
}

export default CaseDetails