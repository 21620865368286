import { NavLink } from "react-router-dom"
import { Tooltip } from "@mui/material"
import { menu } from "../data/menu"
import { MdLogout } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { setIsLoggedIn } from "../store/auth/reducers"

const Sidebar = () => {

    const dispatch = useDispatch()
    const { authDetails } = useSelector(state => state.auth)

    const logout = () => {
        dispatch(setIsLoggedIn(false))
    }

    return (
        <div className="tw-h-full tw-px-2 tw-py-6 tw-flex-col tw-justify-between tw-hidden sm:tw-flex">
            <div className="tw-flex tw-flex-col tw-gap-4">
                {
                    menu.filter(c => c.notRoles === undefined || !c.notRoles.includes(authDetails.role)).map(item => (
                        <NavLink key={item.name} to={item.path}>
                            {({ isActive }) => (
                                <Tooltip title={item.name} placement="right">
                                    <span>
                                        <item.icon className={`tw-text-[40px] tw-text-slate-600 tw-transition-all tw-duration-500 ${isActive && 'tw-bg-gradient-to-tr tw-from-pink-300 tw-to-sky-300 tw-text-sky-700 tw-rounded-lg'} tw-p-1 `} />
                                    </span>
                                </Tooltip>
                            )}
                        </NavLink>
                    ))
                }
            </div>
            <div className="tw-flex tw-justify-center">
                <Tooltip title="Logout" placement="right">
                    <span>
                        <MdLogout className="tw-text-slate-500 tw-cursor-pointer tw-text-3xl" onClick={ logout } />
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}

export default Sidebar