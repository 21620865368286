import { useEffect, useState } from "react"
import AssessmentRadio from "./AssessmentRadio"
import AssessmentTextarea from "./AssessmentTextarea"
import AssessmentTextField from "./AssessmentTextField"

const Question = ({ question, id, setAnswer, disabled=false }) => {

    const [comment, setComment] = useState(question.comment)

    const setChildrenValue = (num, value) => {
        let a = [...question.children]
        a[num] = value
        if(!disabled) setAnswer(id, {...question, children: a})
    }

    useEffect(() => {
        let a = {...question}
        a.comment = comment
        if(!disabled) setAnswer(id, a)
    }, [comment]) // eslint-disable-line

    return (
        <div className="tw-min-w-full md:tw-px-6">
            <div className={`tw-p-6 tw-transition-all tw-duration-300`}>
                <div className="tw-break-words tw-text-sm md:tw-text-base tw-font-semibold">
                    { question.title }
                </div>
                <div className="tw-mt-4 tw-ml-4">
                    {
                        question.type === "radio" ?
                            <AssessmentRadio question={question} id={id} setAnswer={setAnswer} disabled={disabled} />
                        : question.type === "textarea" ?
                            <AssessmentTextarea question={question} id={id} setAnswer={setAnswer} disabled={disabled} />
                        : question.children !== undefined && question.children.length > 0 ?
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                { 
                                    question.children.map((c, i) => (
                                        c.type === "textfield" ?
                                            <AssessmentTextField key={i} question={c} id={i} setAnswer={setChildrenValue} disabled={disabled} />
                                        : null
                                    ))
                                }
                            </div>
                        : null
                    }
                </div>
                <div className="tw-mt-4 tw-underline">
                    <div>Reviewer Comments</div>
                    <textarea className="tw-border-2 tw-border-gray-300 focus:tw-border-blue-600 tw-transition-all tw-duration-300 tw-rounded-xl tw-mt-4 tw-w-[75%] tw-p-4 tw-text-sm tw-outline-none focus:tw-border-tcareRuby tw-resize-none" rows={5} placeholder="Comments" value={comment} onChange={ev => setComment(ev.target.value)} disabled={disabled} />
                </div>
            </div>
        </div>
    )
}

export default Question