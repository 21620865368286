import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { FaCheckCircle } from "react-icons/fa"
import { PopInAnim } from "../data/anims"

const AssessmentTextField = ({ question, id, setAnswer, disabled=false }) => {

    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(question.value)
    }, []) //eslint-disable-line
    useEffect(() => {
        let a = {...question}
        a.value = value
        if(!disabled) setAnswer(id, a)
    }, [value]) // eslint-disable-line
    useEffect(() => {
        if(disabled) setValue(question.value)
    }, [question, disabled])
    return (
        <div>
            <div className="tw-font-semibold tw-text-gray-500 tw-text-sm tw-pb-2">{question.title}</div>
            <div className="tw-flex tw-gap-2 tw-items-center">
                <input type="text" className="tw-outline-none tw-border-2 tw-border-gray-200 focus:tw-border-sky-600 tw-transition-all tw-duration-300 tw-rounded-lg tw-py-1 tw-px-2 focus:tw-border-tcareRuby" value={value} onChange={ev => setValue(ev.target.value)} disabled={disabled} />
                <AnimatePresence>
                    {
                        (value !== null && value.trim() !== "") ?
                            <motion.span variants={PopInAnim} initial="hidden" animate="visible" exit="hidden"><FaCheckCircle className="tw-text-green-600 tw-text-lg" /></motion.span>
                        : null
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}

export default AssessmentTextField