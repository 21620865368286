export const questions = [
    {
        id: 1,
        title: "You are being asked to complete this survey because you help care for a person who is receiving Medicaid insurance coverage and services. Your responses may help us understand the support you provide to the care recipient and if additional services or supports are needed to continue their preferred care setting.",
        type: "radio",
        options: [
            { text: "I understand", value: 0 }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 1
    },
    {
        id: 2,
        title: "How long does it typically take you to travel to the care recipient? (Select the mode of transit you use most frequently)",
        children: [
            {
                title: "Minutes",
                type: "textfield",
                value: ""
            },
            {
                title: "Mode of transit (drive, bus, walk: input all modes of transit utilized the last time you travelled to care recipient).",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 1
    },
    {
        id: 3,
        title: "What is your relationship to the care recipient?",
        type: "radio",
        options: [
            { "text": "Spouse", "value": 0 },
            { "text": "Partner/Significant Other", "value": 1 },
            { "text": "Child", "value": 2 },
            { "text": "Parent", "value": 3 },
            { "text": "Grandparent", "value": 4 },
            { "text": "Other relative", "value": 5 },
            { "text": "Non-relative", "value": 6 }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 1
    },
    {
        id: 4,
        title: "What kind(s) of supervision and assistance do you provide to the care recipient? (Select all that apply)",
        type: "radio",
        options: [
            { "text": "Meal preparation", "value": 0 },
            { "text": "Housework", "value": 1 },
            { "text": "Managing finances", "value": 2 },
            { "text": "Managing medications", "value": 3 },
            { "text": "Phone use", "value": 4 },
            { "text": "Stairs", "value": 5 },
            { "text": "Shopping", "value": 6 },
            { "text": "Transportation", "value": 7 },
            { "text": "Bathing", "value": 8 },
            { "text": "Personal hygiene", "value": 9 },
            { "text": "Dressing", "value": 10 },
            { "text": "Walking", "value": 11 },
            { "text": "Transfer on and off toilet", "value": 12 },
            { "text": "Toilet use (for example: cleanse after use or incontinent episodes)", "value": 13 },
            { "text": "Bed mobility", "value": 14 },
            { "text": "Eating", "value": 15 },
            { "text": "Other", "value": 16 }
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 1
    },
    {
        id: 5,
        title: "How long have you provided the current level of support to the care recipient?",
        children: [
            {
                title: "Years",
                type: "textfield",
                value: ""
            },
            {
                title: "Months",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 1
    },
    {
        id: 6,
        title: "How many hours per week do you provide care to the care recipient?",
        children: [
            {
                title: "Hours per week",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 1
    },
    {
        id: 7,
        title: "Are you also the caregiver for another person?",
        type: "radio",
        options: [
            { "text": "Yes", "value": 0 },
            { "text": "No", "value": 1 }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 1
    },
    {
        id: 8,
        title: "Who else do you provide care for?",
        type: "radio",
        options: [
            {
                "text": "Spouse",
                "value": 0
            },
            {
                "text": "Partner/Significant Other",
                "value": 1
            },
            {
                "text": "Parent",
                "value": 2
            },
            {
                "text": "Child under 18",
                "value": 3
            },
            {
                "text": "Child/Grandchild over 18",
                "value": 4
            },
            {
                "text": "Other relative under 18",
                "value": 5
            },
            {
                "text": "Other relative over 18",
                "value": 6
            },
            {
                "text": "Other relative",
                "value": 7
            },
            {
                "text": "Non-relative",
                "value": 8
            },
            {
                "text": "Other",
                "value": 9
            }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 9,
        title: "Is anyone else providing care for the care recipient?",
        type: "radio",
        options: [
            {
                "text": "If yes, how many others:",
                "value": 0,
                children: [
                    {
                        title: "Paid",
                        type: "textfield",
                        value: ""
                    },
                    {
                        title: "Unpaid (for example: a family member)",
                        type: "textfield",
                        value: ""
                    },
                    {
                        title: "He/she lives in a nursing home",
                        type: "textfield",
                        value: ""
                    }
                ]
            },
            {
                "text": "No",
                "value": 1
            }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 10,
        title: "Are you a student?",
        type: "radio",
        options: [
            {"text": "Enrolled full-time", "value": 0},
            {"text": "Enrolled part-time", "value": 1},
            {"text": "Not a student", "value": 2}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 11,
        title: "Are you employed? (select all that apply)",
        type: "radio",
        options: [
            {"text": "Not employed", "value": 0},
            {"text": "Part-time", "value": 1},
            {"text": "Full-time", "value": 2},
            {"text": "Self-scheduling work (for example: rideshare driver)", "value": 3},
            {"text": "Employed in more than one job (full- and/or part-time)", "value": 4}
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 12,
        title: "If you are employed, how if at all has your employment been affected by your caregiving role?",
        type: "radio",
        options: [
            {"text": "Schedule: Changed jobs", "value": 0},
            {"text": "Schedule: Decreased hours or went part-time", "value": 1},
            {"text": "Schedule: Have taken extended leave with pay", "value": 2},
            {"text": "Schedule: Quit job", "value": 3},
            {"text": "Pay: Have taken a second job", "value": 4},
            {"text": "Pay: Have lost wages or periods with no income", "value": 5},
            {"text": "Pay: Has taken leave without pay", "value": 6},
            {"text": "Pay: Missed promotion opportunity", "value": 7},
            {"text": "Pay: Received pay cut or pay decreased", "value": 8},
            {"text": "Leave: Take leave frequently", "value": 9},
            {"text": "Leave: Used all pay leave; no leave remaining", "value": 10},
            {"text": "Leave: Exceeded Family Medical Leave Act (FMLA)", "value": 11},
            {"text": "Work relationships: Feeling of isolation", "value": 12},
            {"text": "Work relationships: Less co-worker interaction", "value": 13},
            {"text": "Work relationships: Tension or problem with co-worker", "value": 14},
            {"text": "Work relationships: Tension or problem with supervisor", "value": 15},
            {"text": "Performance: Decreasing confidence in my own ability", "value": 16},
            {"text": "Performance: Decrease in productivity", "value": 17},
            {"text": "Performance: Difficulty with concentration or focus", "value": 18},
            {"text": "Performance: Fear of losing my job", "value": 19},
            {"text": "Performance: Perform/manage caregiver tasks at work", "value": 20}
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 13,
        title: "At the end of the month, do you end up with:",
        type: "radio",
        options: [
            {"text": "Some money left over", "value": 0},
            {"text": "Just enough to make ends meet", "value": 1},
            {"text": "Not enough money to make ends meet", "value": 2}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 2
    },
    {
        id: 14,
        title: "Which of the following are true? (select all that apply):",
        type: "radio",
        options: [
            {"text": "You don’t have enough time for yourself", "value": 0},
            {"text": "Stressed between caring and meeting other responsibilities", "value": 1},
            {"text": "The care recipient affects your relationship with others in a negative way", "value": 2},
            {"text": "Strained when are around your relative", "value": 3},
            {"text": "Your health has suffered because of your involvement with your relative", "value": 4},
            {"text": "You have lost control of your life since your relative's illness", "value": 5}
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 15,
        title: "In general, how would you rate your physical health?",
        type: "radio",
        options: [
            {"text": "Excellent", "value": 0},
            {"text": "Very Good", "value": 1},
            {"text": "Good", "value": 2},
            {"text": "Fair", "value": 3},
            {"text": "Poor", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 16,
        title: "To what extent are you able to carry out your everyday physical activities such as walking, climbing stairs, carrying groceries, or moving a chair?",
        type: "radio",
        options: [
            {"text": "Completely", "value": 0},
            {"text": "Mostly", "value": 1},
            {"text": "Moderately", "value": 2},
            {"text": "A little", "value": 3},
            {"text": "Not at all", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 17,
        title: "In the past 7 days, how would you rate your pain on average? (Scale of 0 to 10, where 0 = no pain and 10 = worst pain imaginable)",
        type: "radio",
        options: [
            {"text": "0 (No pain)", "value": 0},
            {"text": "1", "value": 1},
            {"text": "2", "value": 2},
            {"text": "3", "value": 3},
            {"text": "4", "value": 4},
            {"text": "5", "value": 5},
            {"text": "6", "value": 6},
            {"text": "7", "value": 7},
            {"text": "8", "value": 8},
            {"text": "9", "value": 9},
            {"text": "10 (worst pain imaginable)", "value": 10}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 18,
        title: "In the past 7 days, how would you rate your fatigue on average?",
        type: "radio",
        options: [
            {"text": "None", "value": 0},
            {"text": "Mild", "value": 1},
            {"text": "Moderate", "value": 2},
            {"text": "Severe", "value": 3},
            {"text": "Very severe", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 19,
        title: "In general, would you say your quality of life is?",
        type: "radio",
        options: [
            {"text": "Excellent", "value": 0},
            {"text": "Very Good", "value": 1},
            {"text": "Good", "value": 2},
            {"text": "Fair", "value": 3},
            {"text": "Poor", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 20,
        title: "In general, how would you rate your mental health, including your mood and your ability to think?",
        type: "radio",
        options: [
            {"text": "Excellent", "value": 0},
            {"text": "Very Good", "value": 1},
            {"text": "Good", "value": 2},
            {"text": "Fair", "value": 3},
            {"text": "Poor", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 21,
        title: "In general, how would you rate your satisfaction with social activities and relationships?",
        type: "radio",
        options: [
            {"text": "Excellent", "value": 0},
            {"text": "Very Good", "value": 1},
            {"text": "Good", "value": 2},
            {"text": "Fair", "value": 3},
            {"text": "Poor", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 22,
        title: "How often have you been bothered by emotional problems, such as feeling anxious, depressed or irritable?",
        type: "radio",
        options: [
            {"text": "Never", "value": 0},
            {"text": "Rarely", "value": 1},
            {"text": "Sometimes", "value": 2},
            {"text": "Often", "value": 3},
            {"text": "Always", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 23,
        title: "What is your level of agreement with the statement: “I get a great deal of satisfaction from being a caregiver.”",
        type: "radio",
        options: [
            {"text": "100%", "value": 0},
            {"text": "90%", "value": 1},
            {"text": "80%", "value": 2},
            {"text": "70%", "value": 3},
            {"text": "60%", "value": 4},
            {"text": "50%", "value": 5},
            {"text": "40%", "value": 6},
            {"text": "30%", "value": 7},
            {"text": "20%", "value": 8},
            {"text": "10%", "value": 9},
            {"text": "0%", "value": 10}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 24,
        title: "Overall, how would you rate your confidence as a caregiver? Would you say:",
        type: "radio",
        options: [
            {"text": "Very confident", "value": 0},
            {"text": "Somewhat confident", "value": 1},
            {"text": "A little confident", "value": 2},
            {"text": "Not very confident", "value": 3},
            {"text": "Not at all confident", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 3
    },
    {
        id: 25,
        title: "Overall, do you feel like the care recipient is getting all of the support they need?",
        type: "radio",
        options: [
            {"text": "Yes", "value": 0},
            {
                "text": "No",
                "value": 1,
                children: [
                    {
                        title: "Please Describe",
                        type: "textfield",
                        value: ""
                    }
                ]
            }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 26,
        title: "Do you feel like you are getting the support you need to remain in your role as a caregiver?",
        type: "radio",
        options: [
            {"text": "Yes", "value": 0},
            {
                "text": "No",
                "value": 1,
                children: [
                    {
                        title: "Please Describe",
                        type: "textfield",
                        value: ""
                    }
                ]
            }
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 27,
        title: "What is your age?",
        children: [
            {
                title: "Years of age:",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 4
    },
    {
        id: 28,
        title: "What is your gender?",
        type: "radio",
        options: [
            {"text": "Woman", "value": 0},
            {"text": "Man", "value": 1},
            {"text": "Non-Binary or gender non-conforming", "value": 2},
            {"text": "Prefer to self-describe as: __________", "value": 3},
            {"text": "Prefer not to state", "value": 4}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 29,
        title: "What is your race/ethnicity?",
        type: "radio",
        options: [
            {"text": "American Indian or Alaska Native", "value": 0},
            {"text": "Asian", "value": 1},
            {"text": "Black or African American", "value": 2},
            {"text": "Native Hawaiian or Other Pacific Islander", "value": 3},
            {"text": "Some Other Race", "value": 4},
            {"text": "Two or more races", "value": 5},
            {"text": "Hispanic origin", "value": 6},
            {"text": "White", "value": 7}
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 30,
        title: "What is your highest level of education completed?",
        type: "radio",
        options: [
            {"text": "Less than high school", "value": 0},
            {"text": "High school graduate", "value": 1},
            {"text": "Some college", "value": 2},
            {"text": "Two-year college graduate", "value": 3},
            {"text": "Four-year college graduate", "value": 4},
            {"text": "Graduate/Professional School", "value": 5}
        ],
        multiple: false,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 31,
        title: "What is your income?",
        children: [
            {
                title: "Annual income in dollars:",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 4
    },
    {
        id: 32,
        title: "What is your primary/preferred language?",
        type: "radio",
        options: [
            {"text": "English", "value": 0},
            {"text": "Spanish", "value": 1},
            {"text": "Burmese", "value": 2},
            {"text": "French", "value": 3},
            {"text": "Haitian Creole", "value": 4},
            {"text": "Arabic", "value": 5},
            {"text": "Other", "value": 6}
        ],
        multiple: true,
        value: [],
        comment: "",
        page: 4
    },
    {
        id: 33,
        title: "What is your (caregiver) zip code?",
        children: [
            {
                title: "Zip code or Country if outside the US:",
                type: "textfield",
                value: ""
            }
        ],
        comment: "",
        page: 4
    },
    {
        id: 34,
        title: "Please add any further comments or questions you would like addressed.",
        type: "textarea",
        value: "",
        page: 5
    }
]