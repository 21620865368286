import symbol from "../assets/images/symbol.png"

const PoweredBy = () => {

    return (
        <div className="tw-fixed tw-bottom-2 tw-right-4 tw-hidden sm:tw-flex tw-items-center tw-gap-1">
            <span className="tw-font-semibold tw-text-xs">© 2023 TCARE Inc. All rights reserved.</span>
            <img src={symbol} alt="Logo" className="tw-w-5 tw-h-4" />
        </div>
    )
}

export default PoweredBy