import { Autocomplete, Button, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { relationshipOptions, stateOptions } from "../data/menu"
import CaregiverPresent from "./CaregiverPresent"
import API from "../axios/API"
import { getState } from "../data/commons"

const AssessmentDemographics = ({ initialCaregiverId, initialCarereceiverId, initialCaregiver, initialCarereceiver, initialRelationShip, initialAssessmentDetails, takeAssessment }) => {

    const [caregiver, setCaregiver] = useState(initialCaregiver)
    const [carereceiver, setCarereceiver] = useState(initialCarereceiver)
    const [caregiver_id, setCaregiverId] = useState(initialCaregiverId)
    const [relationship_id, setRelationship_id] = useState(initialRelationShip)
    const [assessmentDetails, setAssessmentDetails] = useState(initialAssessmentDetails)
    const [lastVerifiedEmail, setLastVerifiedEmail] = useState('')
    const [cgSubscriberRelation, setCgSubscriberRelation] = useState({})
    const [showCaregiverPresent, setShowCaregiverPresent] = useState(false)

    const handleCaregiverChange = (e) => {
        setCaregiver({...caregiver, [e.target.name]: e.target.value})
    }
    const handleCarereceiverChange = (e) => {
        setCarereceiver({...carereceiver, [e.target.name]: e.target.value})
    }
    const submitDemographics = () => {
        if(lastVerifiedEmail !== caregiver.email) {
            verifyEmail(true)
        } else {
            takeAssessment(caregiver_id, caregiver, carereceiver, relationship_id, assessmentDetails)
        }
    }
    const verifyEmail = (submit=false) => {
        if(caregiver.email !== lastVerifiedEmail && caregiver_id === 0) {
            API.post('/get-email-subscriber-relation', {email: caregiver.email, subscriber_id: assessmentDetails.subscriber_id})
                .then(res => {
                    setCgSubscriberRelation(res.data)
                    if(res.data.caregiver !== null) {
                        setShowCaregiverPresent(true)
                    } else {
                        setLastVerifiedEmail(caregiver.email)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setLastVerifiedEmail(caregiver.email)
                })
        } else if(caregiver_id !== 0) {
            setLastVerifiedEmail(caregiver.email)
        }
    }
    const handleCaregiverPresentClose = () => {
        setCaregiver({...caregiver, email: ''})
        setShowCaregiverPresent(false)
    }
    const addCaregiver = () => {
        setCaregiverId(cgSubscriberRelation.caregiver.id)
        setCaregiver(cgSubscriberRelation.caregiver)
        if(cgSubscriberRelation.related !== null) {
            setAssessmentDetails({...assessmentDetails, case_id: cgSubscriberRelation.case_id})
        }
        setLastVerifiedEmail(caregiver.email)
        setShowCaregiverPresent(false)
    }

    useEffect(() => {
        ValidatorForm.addValidationRule('checkEmpty', (value) => {
            if (value.trim() === "") {
                return false;
            }
            return true;
        });

        return () => ValidatorForm.removeValidationRule('checkEmpty');
    }, [])
    useEffect(() => {
        setLastVerifiedEmail(caregiver.email)
    }, []) // eslint-disable-line

    return (
        <div className="tw-min-h-full tw-w-full tw-flex tw-justify-center tw-items-center">
            <ValidatorForm onSubmit={submitDemographics}>
                <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-10">
                    <div>
                        <div className="tw-text-2xl tw-text-center tw-font-semibold tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-bg-clip-text tw-text-transparent">
                            {caregiver_id.toString() !== '0' ? 'Edit ' : ''} Caregiver Demographics
                        </div>
                        <div className="tw-mt-8 tw-flex tw-flex-col tw-items-center">
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="First Name" name="first_name" value={caregiver.first_name} onChange={handleCaregiverChange} validators={['checkEmpty']} errorMessages={['First Name is required']} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator label="Last Name" name="last_name" value={caregiver.last_name} onChange={handleCaregiverChange} validators={['checkEmpty']} errorMessages={['Last Name is required']} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="Phone" name="phone" value={caregiver.phone} onChange={handleCaregiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator disabled={caregiver_id.toString() !== '0'} label="Email" name="email" value={caregiver.email} onChange={handleCaregiverChange} validators={['checkEmpty', 'isEmail']} errorMessages={['Email is required', 'Email is not valid']} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " onBlur={() => verifyEmail(false)} />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="Address" name="address" value={caregiver.address} onChange={handleCaregiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator label="City" name="city" value={caregiver.city} onChange={handleCaregiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                {/* <TextValidator label="State" name="state" value={caregiver.state} onChange={handleCaregiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " /> */}
                                <Autocomplete options={stateOptions} getOptionLabel={(option) => option.text} value={{text: getState(caregiver.state).text, value: caregiver.state}} onChange={(ev, value) => setCaregiver({ ...caregiver, state: value?.value || '' })} renderInput={(params) => <TextField {...params} label="State" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" size="small" />} className="tw-w-72 sm:tw-w-64 md:tw-w-48 xl:tw-w-64 tw-mb-4" />
                                <TextValidator label="Zip" name="zip" value={caregiver.zip} onChange={handleCaregiverChange} validators={['matchRegexp:^[0-9]{5}$']} errorMessages={'Zip must have 5 digits'} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="tw-text-2xl tw-text-center tw-font-semibold tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-bg-clip-text tw-text-transparent">
                            {initialCarereceiverId.toString() !== '0' ? 'Edit ' : ''} Care Receiver Demographics
                        </div>
                        <div className="tw-mt-8 tw-flex tw-flex-col tw-items-center">
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="First Name" name="first_name" value={carereceiver.first_name} onChange={handleCarereceiverChange} validators={['checkEmpty']} errorMessages={['First Name is required']} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator label="Last Name" name="last_name" value={carereceiver.last_name} onChange={handleCarereceiverChange} validators={['checkEmpty']} errorMessages={['Last Name is required']} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="Phone" name="phone" value={carereceiver.phone} onChange={handleCarereceiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator disabled label="Subscriber ID" name="subscriber_id" value={carereceiver.subscriber_id} onChange={handleCarereceiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <TextValidator label="Address" name="address" value={carereceiver.address} onChange={handleCarereceiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                                <TextValidator label="City" name="city" value={carereceiver.city} onChange={handleCarereceiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                {/* <TextValidator label="State" name="state" value={carereceiver.state} onChange={handleCarereceiverChange} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " /> */}
                                <Autocomplete options={stateOptions} getOptionLabel={(option) => option.text} value={{text: getState(carereceiver.state).text, value: carereceiver.state}} onChange={(ev, value) => setCarereceiver({ ...carereceiver, state: value?.value || '' })} renderInput={(params) => <TextField {...params} label="State" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" size="small" />} className="tw-w-72 sm:tw-w-64 md:tw-w-48 xl:tw-w-64 tw-mb-4" />
                                <TextValidator label="Zip" name="zip" value={carereceiver.zip} onChange={handleCarereceiverChange} validators={['matchRegexp:^[0-9]{5}$']} errorMessages={'Zip must have 5 digits'} size="small" className="tw-w-72 sm:tw-w-64 md:tw-w-auto xl:tw-w-64" helperText=" " />
                            </div>
                            <div className="tw-flex md:tw-gap-4 tw-flex-col md:tw-flex-row">
                                <SelectValidator disabled={caregiver_id.toString() !== '0' && initialCarereceiverId.toString() !== '0'} label="Relationship" name="relationship" value={relationship_id} onChange={ev => setRelationship_id(ev.target.value)} size="small" className="tw-flex-1 tw-w-72 sm:tw-w-64 md:tw-w-48 xl:tw-w-64" validators={["required"]} errorMessages={["Relationship is required"]} helperText=" ">
                                    {
                                        relationshipOptions.map((relationship, index) => {
                                            return (
                                                <MenuItem key={index} value={relationship.value}>{relationship.text}</MenuItem>
                                            )
                                        })
                                    }
                                </SelectValidator>
                                <div className="tw-w-72 sm:tw-w-64 md:tw-w-48 xl:tw-w-64"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-flex tw-justify-center tw-mt-4 tw-mb-8">
                    <Button variant="contained" color="primary" type="submit" className="tw-mt-8 tw-w-64 tw-self-center">Submit</Button>
                </div>
            </ValidatorForm>
            <CaregiverPresent open={showCaregiverPresent} connected={cgSubscriberRelation.related} onClose={handleCaregiverPresentClose} onSuccess={addCaregiver} />
        </div>
    )
}

export default AssessmentDemographics