import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import NotFound from "../assets/images/not_found.json"

const NoRecord = () => {

    const [play, setPlay] = useState(0)
    const lottieRef = useRef(null)

    useEffect(() => {
        let r = setTimeout(() => {
            lottieRef.current.goToAndPlay(0)
        }, 2000)

        return () => {
            clearTimeout(r)
        }
    }, [play])

    return (
        <div className="tw-w-full tw-mt-4 tw-flex tw-flex-col tw-items-center tw-h-full">
            <div className="tw-w-80">
                <Lottie lottieRef={lottieRef} animationData={NotFound} loop={false} onComplete={() => setPlay(play + 1)} />
            </div>
            <div className="tw-text-xl tw-font-semibold tw-text-gray-400 tw-mt-4">
                No Records Found
            </div>
        </div>
    )
}

export default NoRecord