import { Button, IconButton, InputAdornment, TextField } from "@mui/material"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid"
import { useCallback, useEffect, useState } from "react"
import { MdPersonSearch } from "react-icons/md"
import Moment from "react-moment"
import { NavLink } from "react-router-dom"
import DataLoading from "../components/DataLoading"
import NoRecord from "../components/NoRecord"
import { debounce, getRelationship } from "../data/commons"
import API from "../axios/API"
import ActivateCase from "../components/ActivateCase"
import { IoMdCloseCircle } from "react-icons/io"

const Cases = () => {

    const [search, setSearch] = useState("")
    const [rows, setRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [loading, setLoading] = useState(true)
    const [initialDataFilled, setInitialDataFilled] = useState(false)
    const [alterCaseId, setAlterCaseId] = useState(0)
    const [alterCaseIsActive, setAlterCaseIsActive] = useState(false)
    const [alterCaseOpen, setAlterCaseOpen] = useState(false)
    const [columnModel, setColumnModel] = useState({
        updated_by: false,
        updated_at: false,
    })

    const columns = [
        { field: "id", headerName: "ID", minWidth: 100, flex: 1 },
        { field: "caregiver", headerName: "Caregiver", minWidth: 150, flex: 1, renderCell: (params) => (
            params.row.caregiver.trim() === "" && params.row.relationship_id.toString() === "0"
                ? <span className="tw-text-yellow-600">No Caregiver Available</span>
                : params.row.caregiver
            )
        },
        { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
        { field: "carereceiver", headerName: "Care Receiver", minWidth: 150, flex: 1 },
        { field: "subscriber_id", headerName: "Subscriber ID", minWidth: 200, flex: 1 },
        { field: "relationship_id", headerName: "Relationship", minWidth: 200, flex: 1, valueGetter: (params) => getRelationship(params.row.relationship_id).text },
        { field: "created_by", headerName: "Created By", minWidth: 200, flex: 1},
        { field: "updated_by", headerName: "Updated By", minWidth: 200, flex: 1},
        { field: "created_at", headerName: "Created At", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.created_at}</Moment> },
        { field: "updated_at", headerName: "Updated At", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.updated_at}</Moment> },
        { field: "action", headerName: "Action", minWidth: 200, flex: 1, renderCell: (params) => (
            <div className="tw-flex tw-gap-2 tw-justify-center">
                <NavLink to={`/case/${params.row.id}`}>
                    <Button type="button" size="small" variant="contained" color="primary">View</Button>
                </NavLink>
                <Button type="button" size="small" variant="contained" color={params.row.is_active ? 'error' : 'success'} onClick={() => startAlterCase(params.row.id)}>{params.row.is_active ? 'Deactivate' : 'Activate'}</Button>
            </div>
        )}
    ]

    const getCases = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get("/cases")
            .then(res => {
                setRows(res.data)
                setInitialDataFilled(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setInitialDataFilled(true)
            })
    }, 200), [])
    const CustomToolbar = useCallback(() => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
            </GridToolbarContainer>
        );
    }, [])
    const startAlterCase = (id) => {
        setAlterCaseId(id)
        setAlterCaseIsActive(rows.find(row => row.id === id).is_active)
        setAlterCaseOpen(true)
    }
    const caseAltered = () => {
        setAlterCaseOpen(false)
        getCases()
    }

    useEffect(() => {
        getCases()
    }, []) // eslint-disable-line
    useEffect(() => {
        if(search.trim() === "") setFilteredRows(rows)
        else setFilteredRows(rows.filter(row => Object.keys(row).some(key => row[key].toString().toLowerCase().includes(search.toLowerCase()))))
        if(initialDataFilled) setLoading(false)
    }, [search, rows]) // eslint-disable-line

    return (
        <div className="tw-w-full tw-mt-4 tw-pr-4">
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-items-end sm:tw-justify-between">
                <div className="tw-font-bold tw-text-xl tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-inline-block tw-bg-clip-text tw-text-transparent">Cases</div>
                <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                    <TextField value={search} onChange={ev => setSearch(ev.target.value)} className="md:tw-w-96" label="Search" size="small" InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdPersonSearch className="tw-text-2xl" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            search.trim() !== "" &&<IconButton onClick={() => setSearch("")}>
                                <IoMdCloseCircle className="tw-text-lg" />
                            </IconButton>
                        )
                    }} />
                </div>
            </div>
            <div className="tw-mt-4">
                {
                    loading
                        ? <DataLoading />
                        : (filteredRows.length === 0)
                            ? <NoRecord />
                            : <div className="tw-bg-sky-50 tw-border">
                                <DataGrid rows={filteredRows} columns={columns} pageSize={rowsPerPage} page={page} onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)} onPageChange={params => setPage(params.page)} autoHeight={true} components={{Toolbar: CustomToolbar}} rowsPerPageOptions={[5, 10, 20]} columnVisibilityModel={columnModel} onColumnVisibilityModelChange={newModel => setColumnModel(newModel)} />
                            </div>
                }
            </div>
            <ActivateCase case_id={alterCaseId} is_active={alterCaseIsActive} open={alterCaseOpen} onClose={() => setAlterCaseOpen(false)} onSuccess={caseAltered} />
        </div>
    )
}

export default Cases