import Lottie from "lottie-react";
import loading from "../assets/images/loading.json"

const DataLoading = () => {

    return (
        <div className="tw-w-full tw-mt-4 tw-flex tw-flex-col tw-items-center tw-h-full">
            <div className="tw-w-80 sm:tw-w-96 md:tw-w-[500px]">
                <Lottie animationData={loading} loop={true} />
            </div>
        </div>
    )
}

export default DataLoading