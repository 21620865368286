import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { useDispatch } from "react-redux"
import API from "../axios/API"
import { getState } from "../data/commons"
import { stateOptions } from "../data/menu"
import { setAlert } from "../store/auth/reducers"
import DataLoading from "./DataLoading"

const UserForm = ({ open, user_id, onClose, onSuccess }) => {

    const emptyUser = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        role: 1,
    }
    const form = useRef(null)
    const [user, setUser] = useState(emptyUser)
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))
    const dispatch = useDispatch()

    useEffect(() => {
        ValidatorForm.addValidationRule('checkEmpty', (value) => {
            if (value.trim() === "") {
                return false;
            }
            return true;
        });

        return () => ValidatorForm.removeValidationRule('checkEmpty');
    }, [])

    const handleChange = (ev) => {
        setUser({ ...user, [ev.target.name]: ev.target.value })
    }
    const resetForm = () => {
        setUser(emptyUser)
    }
    const validateForm = () => {
        form.current.submit()
    }
    const submitForm = () => {
        setSubmitLoading(true)
        if(user_id === 0) {
            API.post("/user", user)
            .then(res => {
                dispatch(setAlert({open: true, message: "User added successfully", severity: "success"}))
                resetForm()
                onSuccess()
                setSubmitLoading(false)
            })
            .catch(err => {
                dispatch(setAlert({open: true, message: "Error adding user", severity: "error"}))
                console.log(err)
                setSubmitLoading(false)
            })
        } else {
            API.put(`/user/${user_id}`, user)
            .then(res => {
                dispatch(setAlert({open: true, message: "User updated successfully", severity: "success"}))
                resetForm()
                onSuccess()
                setSubmitLoading(false)
            })
            .catch(err => {
                dispatch(setAlert({open: true, message: "Error updating user", severity: "error"}))
                console.log(err)
                setSubmitLoading(false)
            })
        }
    }
    useEffect(() => {
        resetForm()
    }, [open]) // eslint-disable-line
    useEffect(() => {
        if(user_id !== 0) {
            setLoading(true)
            API.get(`/user/${user_id}`)
            .then(res => {
                setUser(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        }
    }, [user_id])

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">{user_id === 0 ? "Add User" : "Edit User"}</span>
            </DialogTitle>
            <DialogContent>
                { loading ? <DataLoading /> :
                    <ValidatorForm ref={form} onSubmit={submitForm} instantValidate={true}>
                        <div className="tw-py-4 tw-flex tw-flex-col tw-gap-1 tw-items-center">
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="First Name" name="first_name" value={user.first_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" autoFocus helperText=" " size="small" />
                                <TextValidator label="Last Name" name="last_name" value={user.last_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Email" name="email" value={user.email} onChange={handleChange} validators={["checkEmpty", "isEmail"]} errorMessages={["This field is required", "Email is not valid"]} className="tw-w-64" helperText=" " size="small" disabled={user_id !== 0} />
                                <TextValidator label="Phone" name="phone" value={user.phone} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Address" name="address" value={user.address} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                                <TextValidator label="City" name="city" value={user.city} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                {/* <TextValidator label="State" name="state" value={user.state} onChange={handleChange} className="tw-w-64" size="small" /> */}
                                <Autocomplete options={stateOptions} getOptionLabel={(option) => option.text} value={{text: getState(user.state).text, value: user.state}} onChange={(ev, value) => setUser({ ...user, state: value?.value || '' })} renderInput={(params) => <TextField {...params} label="State" className="tw-w-64" size="small" />} className="tw-w-64 tw-mb-4" />
                                <TextValidator label="Zip" name="zip" value={user.zip} onChange={handleChange} validators={['matchRegexp:^[0-9]{5}$']} errorMessages={'Zip must have 5 digits'} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <SelectValidator label="Role" name="role" value={user.role} onChange={handleChange} className="tw-w-64" helperText=" " size="small">
                                    <MenuItem value={1}>Screener</MenuItem>
                                    <MenuItem value={2}>Admin</MenuItem>
                                </SelectValidator>
                                <div className="tw-w-64"></div>
                            </div>
                        </div>
                    </ValidatorForm>
                }
            </DialogContent>
            <DialogActions>
                { !loading &&
                    <>
                        <Button disabled={submitLoading} loading onClick={onClose}>Cancel</Button>
                        <Button disabled={submitLoading} onClick={validateForm}>Submit</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}

export default UserForm