import { createTheme } from "@mui/material";

export const headCellProp = value => {
    return {
        style: {
            backgroundColor: 'transparent',
        },
    };
}

export const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MUIDataTableSelectCell: {
                styleOverrides: {
                    headerCell: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                        '& .MuiToolbar-root': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                    }
                }
            }
        },
    });