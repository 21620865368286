import { Button, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"
import { MdPersonSearch } from "react-icons/md"
import { HiPlus } from "react-icons/hi"
import { useCallback, useEffect, useMemo, useState } from "react"
import { debounce, getRole, getState } from "../data/commons"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid"
import NoRecord from "../components/NoRecord"
import API from "../axios/API"
import UserForm from "../components/UserForm"
import Moment from "react-moment"
import DataLoading from "../components/DataLoading"
import DeleteUser from "../components/DeleteUser"
import { IoMdCloseCircle } from "react-icons/io"

const Users = () => {

    const [search, setSearch] = useState("")
    const [rows, setRows] = useState([])
    const [filteredRows, setFilteredRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showUserForm, setShowUserForm] = useState(false)
    const [editUserId, setEditUserId] = useState(0)
    const [loading, setLoading] = useState(true)
    const [initialDataFilled, setInitialDataFilled] = useState(false)
    const [filterModel, setFilterModel] = useState({items: []})
    const [columnModel, setColumnModel] = useState({
        city: false,
        state: false,
        zip: false,
        updated_at: false,
    })
    const [showDeleteUser, setShowDeleteUser] = useState(false)

    const columns = [
        { field: "id", headerName: "ID", minWidth: 100, flex: 1 },
        { field: "name", headerName: "Name",  valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`, minWidth: 150, flex: 1 },
        { field: "email", headerName: "Email", minWidth: 200, flex: 1 },
        { field: "phone", headerName: "Phone", minWidth: 150, flex: 1 },
        { field: "city", headerName: "City", minWidth: 150, flex: 1 },
        { field: "state", headerName: "State", minWidth: 150, flex: 1, valueGetter: (params) => getState(params.row.state).text },
        { field: "zip", headerName: "Zip", minWidth: 150, flex: 1 },
        { field: "role", headerName: "Role", minWidth: 150, flex: 1, renderCell: (params) => <span className="tw-bg-slate-300 tw-text-xs tw-py-0.5 tw-px-2 tw-rounded-xl">{getRole(params.row.role)}</span> },
        { field: "created_at", headerName: "Created At", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.created_at}</Moment> },
        { field: "updated_at", headerName: "Updated At", width:200, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.updated_at}</Moment> },
        { field: "action", headerName: "Action", minWidth: 200, flex: 1, renderCell: (params) => (
            <div className="tw-flex tw-gap-2 tw-justify-center">
                <Button size="small" variant="contained" color="primary" onClick={() => editUser(params.row.id)}>Edit</Button>
                <Button size="small" variant="contained" color="error" onClick={() => deleteUser(params.row.id)}>Delete</Button>
            </div>
        )}
    ]

    const getUsers = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get("/users")
            .then(res => {
                setRows(res.data)
                setInitialDataFilled(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setInitialDataFilled(true)
            })
    }, 200), [])
    const addUser = () => {
        setShowUserForm(true)
    }
    const editUser = (id) => {
        setEditUserId(id)
        setShowUserForm(true)
    }
    const deleteUser = (id) => {
        setEditUserId(id)
        setShowDeleteUser(true)
    }
    const onClose = () => {
        setShowUserForm(false)
        setShowDeleteUser(false)
        setEditUserId(0)
    }
    const onSuccess = () => {
        getUsers()
        onClose()
    }
    const CustomToolbar = useCallback(() => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
            </GridToolbarContainer>
        );
    }, [])

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line
    useEffect(() => {
        if(search.trim() === "") setFilteredRows(rows)
        else setFilteredRows(rows.filter(row => Object.keys(row).some(key => row[key].toString().toLowerCase().includes(search.toLowerCase()))))
        if(initialDataFilled) setLoading(false)
    }, [search, rows]) // eslint-disable-line
    const deleteUserName = useMemo(() => {
        const user = filteredRows.find(row => row.id === editUserId)
        return user ? `${user.first_name} ${user.last_name}` : ""
    }, [editUserId, filteredRows])

    return (
        <div className="tw-w-full tw-mt-4 tw-pr-4">
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-items-end sm:tw-justify-between">
                <div className="tw-font-bold tw-text-xl tw-bg-gradient-to-tr tw-from-pink-500 tw-to-sky-500 tw-inline-block tw-bg-clip-text tw-text-transparent">Users</div>
                <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                    <TextField value={search} onChange={ev => setSearch(ev.target.value)} className="md:tw-w-96" label="Search" size="small" InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdPersonSearch className="tw-text-2xl" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            search.trim() !== "" &&<IconButton onClick={() => setSearch("")}>
                                <IoMdCloseCircle className="tw-text-lg" />
                            </IconButton>
                        )
                    }} />
                    <Tooltip title="Add User">
                        <Button size="small" className="tw-bg-gradient-to-tr tw-from-green-500 tw-to-sky-500" onClick={addUser}>
                            <span className="tw-flex tw-items-center tw-px-2 tw-gap-1">
                                <HiPlus className="tw-text-xl tw-text-white" />
                                <span className="tw-text-white tw-font-semibold tw-hidden sm:tw-inline-block">Add User</span>
                            </span>
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className="tw-mt-4">
                {
                    loading
                        ? <DataLoading />
                        : (filteredRows.length === 0)
                            ? <NoRecord />
                            : <div className="tw-bg-sky-50 tw-border">
                                <DataGrid rows={filteredRows} columns={columns} pageSize={rowsPerPage} page={page} onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)} onPageChange={params => setPage(params.page)} autoHeight={true} components={{Toolbar: CustomToolbar}} rowsPerPageOptions={[5, 10, 20]} filterModel={filterModel} onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)} columnVisibilityModel={columnModel} onColumnVisibilityModelChange={newModel => setColumnModel(newModel)} />
                            </div>
                }
            </div>
            <UserForm open={showUserForm} user_id={editUserId} onClose={onClose} onSuccess={onSuccess} />
            <DeleteUser open={showDeleteUser} user_id={editUserId} name={deleteUserName} onClose={onClose} onSuccess={onSuccess} />
        </div>
    )
}

export default Users