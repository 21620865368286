import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import API from "../axios/API"
import { setAlert } from "../store/auth/reducers"

const ActivateCase = ({ open, case_id, is_active, onClose, onSuccess }) => {

    const [loading, setLoading] = useState(false)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))
    const dispatch = useDispatch()

    const alterCase = () => {
        setLoading(true)
        API.put(`/activate-case/${case_id}`, {id: case_id, is_active: !is_active})
        .then(res => {
            dispatch(setAlert({open: true, message: `Case ${is_active ? 'Deactivated' : 'Activated'} Successfully`, severity: "success"}))
            setLoading(false)
            onSuccess()
        })
        .catch(err => {
            dispatch(setAlert({open: true, message: "Something went wrong", severity: "error"}))
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">{is_active ? 'Deactivate' : 'Activate'} Case</span>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to {is_active ? 'deactivate' : 'activate'} <span className="tw-text-lg">"{case_id}"</span>?
            </DialogContent>
            <DialogActions>
                <Button color="success" variant="outlined" disabled={loading} onClick={onClose}>No, Cancel</Button>
                <Button color={is_active ? "error" : "success"} variant="contained" disabled={loading} onClick={alterCase}>Yes, {is_active ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActivateCase