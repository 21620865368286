import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { useDispatch } from "react-redux"
import DataLoading from "./DataLoading"
import { setAlert } from "../store/auth/reducers"
import API from "../axios/API"
import { stateOptions } from "../data/menu"
import { getState } from "../data/commons"

const CaregiverForm = ({ open, caregiver_id, onClose, onSuccess }) => {

    const emptyCaregiver = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: ""
    }
    const form = useRef(null)
    const [caregiver, setCaregiver] = useState({...emptyCaregiver})
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const { breakpoints } = useTheme();
    const fullScreen = useMediaQuery(breakpoints.down('md'))
    const dispatch = useDispatch()

    useEffect(() => {
        ValidatorForm.addValidationRule('checkEmpty', (value) => {
            if (value.trim() === "") {
                return false;
            }
            return true;
        });

        return () => ValidatorForm.removeValidationRule('checkEmpty');
    }, [])

    const handleChange = (ev) => {
        setCaregiver({ ...caregiver, [ev.target.name]: ev.target.value })
    }
    const resetForm = () => {
        setCaregiver({...emptyCaregiver})
    }
    const validateForm = () => {
        form.current.submit()
    }
    const submitForm = () => {
        setSubmitLoading(true)
        API.put(`/caregiver/${caregiver_id}`, caregiver)
            .then(res => {
                dispatch(setAlert({ open: true, message: "Caregiver updated", severity: "success" }))
                onSuccess()
                setSubmitLoading(false)
            })
            .catch(err => {
                dispatch(setAlert({ open: true, message: "Error updating caregiver", severity: "error" }))
                setSubmitLoading(false)
            })
    }
    useEffect(() => {
        if(!open) {
            resetForm()
        } else if (caregiver_id !== 0 && open) {
            setLoading(true)
            API.get(`/caregiver/${caregiver_id}`)
                .then(res => {
                    setCaregiver(res.data)
                    setLoading(false)
                })
                .catch(err => {
                    dispatch(setAlert({ open: true, message: "Error loading caregiver", severity: "error" }))
                    setLoading(false)
                })
        }
    }, [caregiver_id, open]) // eslint-disable-line

    return (
        <Dialog open={open} fullScreen={fullScreen} scroll="paper" onClose={onClose}>
            <DialogTitle>
                <span className="tw-font-semibold tw-text-2xl">Edit Caregiver</span>
            </DialogTitle>
            <DialogContent>
                { loading ? <DataLoading /> :
                    <ValidatorForm ref={form} onSubmit={submitForm}>
                        <div className="tw-py-4 tw-flex tw-flex-col tw-gap-1 tw-items-center">
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="First Name" name="first_name" value={caregiver.first_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" autoFocus helperText=" " size="small" />
                                <TextValidator label="Last Name" name="last_name" value={caregiver.last_name} onChange={handleChange} validators={["checkEmpty"]} errorMessages={["This field is required"]} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Email" name="email" value={caregiver.email} onChange={handleChange} validators={["checkEmpty", "isEmail"]} errorMessages={["This field is required", "Email is not valid"]} className="tw-w-64" helperText=" " size="small" disabled={true} />
                                <TextValidator label="Phone" name="phone" value={caregiver.phone} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                <TextValidator label="Address" name="address" value={caregiver.address} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                                <TextValidator label="City" name="city" value={caregiver.city} onChange={handleChange} className="tw-w-64" helperText=" " size="small" />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                                {/* <TextValidator label="State" name="state" value={caregiver.state} onChange={handleChange} className="tw-w-64" size="small" /> */}
                                <Autocomplete options={stateOptions} getOptionLabel={(option) => option.text} value={{text: getState(caregiver.state).text, value: caregiver.state}} onChange={(ev, value) => setCaregiver({ ...caregiver, state: value?.value || '' })} renderInput={(params) => <TextField {...params} label="State" className="tw-w-64" size="small" />} className="tw-w-64 tw-mb-4" />
                                <TextValidator label="Zip" name="zip" value={caregiver.zip} onChange={handleChange} validators={['matchRegexp:^[0-9]{5}$']} errorMessages={'Zip must have 5 digits'} className="tw-w-64" helperText=" " size="small" />
                            </div>
                        </div>
                    </ValidatorForm>
                }
            </DialogContent>
            <DialogActions>
                { !loading &&
                    <>
                        <Button variant="outlined" color="error" disabled={submitLoading} loading onClick={onClose}>Cancel</Button>
                        <Button variant="contained" color="success" disabled={submitLoading} onClick={validateForm}>Submit</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}

export default CaregiverForm