const Loader = ({ showLoginMsg, loginMsg }) => {

    return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-min-h-screen tw-w-full tw-bg-sky-50">
            <svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px" viewBox="0 0 100 100">
                <g transform="translate(20 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#0051a2">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.4s"></animateTransform>
                    </rect></g>
                <g transform="translate(50 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#1b75be">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s"></animateTransform>
                    </rect></g>
                <g transform="translate(80 20)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#408ee0">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
                    </rect></g>
                <g transform="translate(20 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#1b75be">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.3s"></animateTransform>
                    </rect></g>
                <g transform="translate(50 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#408ee0">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
                    </rect></g>
                <g transform="translate(80 50)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#89bff8">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s"></animateTransform>
                    </rect></g>
                <g transform="translate(20 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#408ee0">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.2s"></animateTransform>
                    </rect></g>
                <g transform="translate(50 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#89bff8">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="-0.1s"></animateTransform>
                    </rect></g>
                <g transform="translate(80 80)">
                    <rect x="-15" y="-15" width="30" height="30" fill="#e3f1ff">
                        <animateTransform attributeName="transform" type="scale" repeatCount="indefinite" calcMode="spline" dur="1s" values="1;1;0.2;1;1" keyTimes="0;0.2;0.5;0.8;1" keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5" begin="0s"></animateTransform>
                    </rect></g>
            </svg>
            { showLoginMsg && <div className="tw-text-2xl tw-pt-10 tw-text-sky-800 tw-font-bold">{ loginMsg }</div> }
        </div>
    )
}

export default Loader