import { useEffect, useState } from "react"
import AssessmentDemographics from "../components/AssessmentDemographics"
import Assessment from "../components/Assessment"
import SubscriberSearch from "../components/SubscriberSearch"
import LoadingDots from "../components/LoadingDots"
import AssessmentCompleted from "../components/AssessmentCompleted"
import API from "../axios/API"
import { useDispatch } from "react-redux"
import { setAlert } from "../store/auth/reducers"
import { useLocation } from "react-router-dom"

const NewAssessment = () => {

    const [showDemographics, setShowDemographics] = useState(false)
    const [showAssessment, setShowAssessment] = useState(false)
    const [assessmentDetails, setAssessmentDetails] = useState({case_id: 0, subscriber_id: 0, reason: 0})
    const [caregiver, setCaregiver] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    })
    const [carereceiver, setCarereceiver] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        subscriber_id: '',
    })
    const [caregiver_id, setCaregiverId] = useState(0)
    const [carereceiver_id, setCarereceiverId] = useState(0)
    const [relationship_id, setRelationship_id] = useState("")
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { initial, reassess } = state || {}

    const startDemographics = (case_id, subscriber_id, reason, carereceiver_id) => {
        setCarereceiver({...carereceiver, subscriber_id})
        setAssessmentDetails({case_id, subscriber_id, reason})
        setCarereceiverId(carereceiver_id)
        if(case_id.toString() !== '0' || carereceiver_id.toString() !== '0') {
            setLoading(true)
            API.post('/get-new-assessment-details', {case_id, carereceiver_id})
                .then(res => {
                    if(case_id.toString() !== '0') {
                        setCaregiver(res.data.caregiver)
                        setCarereceiver(res.data.carereceiver)
                        setCaregiverId(res.data.caregiver.id)
                        setRelationship_id(res.data.relationship_id)
                    } else if(carereceiver_id.toString() !== '0') {
                        setCarereceiver(res.data.carereceiver)
                    }
                    setLoading(false)
                    setShowDemographics(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    setShowDemographics(true)
                }
            )
        } else {
            setShowDemographics(true)
        }
    }
    const startAssessment = (caregiver_id, caregiver, carereceiver, relationship_id, details) => {
        setCaregiverId(caregiver_id)
        setCaregiver(caregiver)
        setCarereceiver(carereceiver)
        setRelationship_id(relationship_id)
        setAssessmentDetails(details)
        setShowAssessment(true)
    }
    const onSubmitAssessment = (assessment) => {
        setLoading(true)
        API.post('/new-assessment', {caregiver_id, carereceiver_id, case_id: assessmentDetails.case_id || 0, caregiver, carereceiver, reason: assessmentDetails.reason, relationship_id, assessment})
            .then(res => {
                setLoading(false)
                setCompleted(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                dispatch(setAlert({open: true, message: "Something went wrong, try again", severity: "error"}))
            })
    }

    useEffect(() => {
        if(reassess !== undefined && reassess) {
            startDemographics(initial.case_id, initial.subscriber_id, initial.reason, initial.carereceiver_id)
        }
    }, []) // eslint-disable-line

    return (
        <div className="tw-w-full tw-h-full tw-px-4 tw-py-4">
            {
                completed ?
                    <AssessmentCompleted />
                :
                    !showDemographics
                        ? <SubscriberSearch startDemographics={(case_id, subscriber_id, reason, carereceiver_id) => startDemographics(case_id, subscriber_id, reason, carereceiver_id)} />
                        : <>
                            {!showAssessment &&
                                <div className={`${!showAssessment ? 'tw-block' : 'tw-hidden'}`}>
                                    <AssessmentDemographics initialCaregiverId={caregiver_id} initialCarereceiverId={carereceiver_id} initialCaregiver={caregiver} initialCarereceiver={carereceiver} initialRelationShip={relationship_id} takeAssessment={(caregiver_id, caregiver, carereceiver, relationship_id, assessmentDetails) => startAssessment(caregiver_id, caregiver, carereceiver, relationship_id, assessmentDetails)} initialAssessmentDetails={assessmentDetails} />
                                </div>
                            }
                            <div className={`tw-h-full ${showAssessment ? 'tw-block' : 'tw-hidden'}`}>
                                <Assessment onEditDemographics={() => setShowAssessment(false)} onSubmitAssessment={(assessment) => onSubmitAssessment(assessment)} />
                            </div>
                        </>
            }
            { loading && 
                <div className={`tw-w-full tw-h-full tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-bg-black tw-bg-opacity-20 tw-z-30 tw-flex tw-justify-center tw-items-center`}>
                    <LoadingDots />
                </div>
            }
        </div>
    )
}

export default NewAssessment