import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import { debounce, getRelationship } from "../data/commons";
import DataLoading from "./DataLoading";
import API from "../axios/API";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import NoRecord from "./NoRecord";
import Moment from "react-moment";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ActivateCase from "./ActivateCase";

const AssociatedCarereceiverrCases = ({ open, carereceiver_id, onClose }) => {

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [alterCaseId, setAlterCaseId] = useState(0)
    const [alterCaseIsActive, setAlterCaseIsActive] = useState(false)
    const [alterCaseOpen, setAlterCaseOpen] = useState(false)
    const [columnModel, setColumnModel] = useState({
        updated_by: false,
        updated_at: false,
    })

    const columns = [
        { field: "id", headerName: "ID", minWidth: 100, flex: 1 },
        { field: "caregiver", headerName: "Caregiver", minWidth: 150, flex: 1 },
        { field: "email", headerName: "Caregiver Email", minWidth: 150, flex: 1 },
        { field: "carereceiver", headerName: "Care Receiver", minWidth: 150, flex: 1 },
        { field: "subscriber_id", headerName: "Subscriber ID", minWidth: 200, flex: 1 },
        { field: "relationship_id", headerName: "Relationship", minWidth: 200, flex: 1, valueGetter: (params) => getRelationship(params.row.relationship_id).text },
        { field: "created_by", headerName: "Created By", minWidth: 200, flex: 1},
        { field: "updated_by", headerName: "Updated By", minWidth: 200, flex: 1},
        { field: "created_at", headerName: "Created At", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.created_at}</Moment> },
        { field: "updated_at", headerName: "Updated At", minWidth: 200, flex: 1, renderCell: (params) => <Moment format="MMM, DD YYYY hh:mm a">{params.row.updated_at}</Moment> },
        { field: "action", headerName: "Action", minWidth: 200, flex: 1, renderCell: (params) => (
            <div className="tw-flex tw-gap-2 tw-justify-center">
                <NavLink to={`/case/${params.row.id}`}>
                    <Button type="button" size="small" variant="contained" color="primary">View</Button>
                </NavLink>
                <Button type="button" size="small" variant="contained" color={params.row.is_active ? 'error' : 'success'} onClick={() => startAlterCase(params.row.id)}>{params.row.is_active ? 'Deactivate' : 'Activate'}</Button>
            </div>
        )}
    ]

    const getCases = useCallback(debounce(() => { // eslint-disable-line
        setLoading(true)
        API.get("/get-carereceiver-associated-cases/" + carereceiver_id)
            .then(res => {
                setRows(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, 200), [carereceiver_id])
    const CustomToolbar = useCallback(() => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
            </GridToolbarContainer>
        );
    }, [])
    const startAlterCase = (id) => {
        setAlterCaseId(id)
        setAlterCaseIsActive(rows.find(row => row.id === id).is_active)
        setAlterCaseOpen(true)
    }
    const caseAltered = () => {
        setAlterCaseOpen(false)
        getCases()
    }
    
    useEffect(() => {
        if (open) {
            getCases()
        }
    }, [open, carereceiver_id]) // eslint-disable-line

    return (
        <Dialog open={open} fullScreen={true} scroll="paper" onClose={onClose} width="1000px">
            <DialogTitle>
                <div className="tw-flex tw-justify-between tw-items-center">
                    <span className="tw-font-semibold tw-text-2xl">Associated Cases</span>
                    <IconButton onClick={onClose} className="tw-absolute tw-right-0 tw-top-0 tw-m-2">
                        <FaTimes className="tw-text-red-600" />
                    </IconButton>
                </div>
                <ActivateCase case_id={alterCaseId} is_active={alterCaseIsActive} open={alterCaseOpen} onClose={() => setAlterCaseOpen(false)} onSuccess={caseAltered} />
            </DialogTitle>
            <DialogContent>
            {
                    loading
                        ? <DataLoading />
                        : (rows.length === 0)
                            ? <NoRecord />
                            : <div className="tw-bg-sky-50 tw-border tw-w-full">
                                <DataGrid rows={rows} columns={columns} pageSize={rowsPerPage} page={page} onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)} onPageChange={params => setPage(params.page)} autoHeight={true} components={{Toolbar: CustomToolbar}} rowsPerPageOptions={[5, 10, 20]} columnVisibilityModel={columnModel} onColumnVisibilityModelChange={newModel => setColumnModel(newModel)} />
                            </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default AssociatedCarereceiverrCases