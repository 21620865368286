import { Button, Step, StepLabel, Stepper } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useMemo, useRef, useState } from "react"
import { FaCheckCircle } from "react-icons/fa"
import { MdOutlineKeyboardBackspace } from "react-icons/md"
import { PopInAnim } from "../data/anims"
import { questions } from "../data/question"
import Question from "./Question"
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi"
import { useNavigate } from "react-router-dom"

const Assessment = ({ onEditDemographics, onSubmitAssessment, initialValues, readonly=false }) => {

    const elem = useRef(null)
    const [activeStep, setActiveStep] = useState(0)
    const [values, setValues] = useState(questions)
    const [pages, setPages] = useState({})
    const [pagesSet, setPagesSet] = useState(false)
    const [prevPage, setPrevPage] = useState(0)
    const navigate = useNavigate()

    const setAnswer = (id, val) => {
        let num = values.findIndex(c => c.id === id)
        let a = [...values]
        a[num] = val 
        setValues(a)
    }
    const validate = (ques) => {
        if(ques.type === "radio") {
            if(ques.value.length > 0 && ques.options[ques.value[0]].children !== undefined) {
                return ques.options[ques.value[0]].children.every(c => validate(c))
            } else return ques.value.length > 0
        } else if(ques.children !== undefined && ques.children.length > 0) {
            return ques.children.every(c => c.value !==null && c.value.trim() !== "")
        } else return ques.value !==null && ques.value.trim() !== ""
    }
    const validatePage = (page) => {
        let valid = values.filter(c => c.page === pages[page].page).every(c => validate(c))
        setPages({...pages, [page]: {...pages[page], completed: valid, error: !valid}})
        return valid
    }
    const submitAssessment = () => {
        onSubmitAssessment(values)
    }
    const backToCase = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/cases', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
    }

    useEffect(() => {
        if(readonly) {
            setValues(initialValues)
        }
    }, [initialValues, readonly])
    useEffect(() => {
        const pages = {}
        Array.from(new Set(questions.map(c => c.page))).forEach((page, i) => {
            pages[i] = {page, completed: false, error: false, touched: false}
        })
        setPages(pages)
        setPagesSet(true)
    }, [])
    useEffect(() => {
        if(pagesSet) {
            validatePage(activeStep)
        }
    }, [values, pagesSet]) // eslint-disable-line
    useEffect(() => {
        if(pagesSet) {
            setPages({...pages, [prevPage]: {...pages[prevPage], touched: true}})
        }
        setPrevPage(activeStep)
        elem.current.scrollTo({top: 0, behavior: 'smooth'})
    }, [activeStep]) // eslint-disable-line

    const allPagesValid = useMemo(() => {
        return Object.keys(pages).every(c => pages[c].completed)
    }, [pages])

    return (
        <div className="tw-relative tw-flex tw-flex-col tw-h-full">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 sm:tw-gap-8 tw-z-20 tw-bg-sky-50 tw-pb-2">
                <div>
                    {
                        readonly ?
                            <Button variant="contained" color="primary" size="small" onClick={backToCase}>
                                <MdOutlineKeyboardBackspace className="tw-mr-2 tw-text-lg" />
                                <span className="tw-text-[10px] lg:tw-hidden">Back to Case</span>
                                <span className="tw-hidden lg:tw-inline">Back to Case</span>
                            </Button>
                        :
                            <Button variant="contained" color="primary" size="small" onClick={onEditDemographics}>
                                <MdOutlineKeyboardBackspace className="tw-mr-2 tw-text-lg" />
                                <span className="tw-text-[10px] lg:tw-hidden">Edit demographics</span>
                                <span className="tw-hidden lg:tw-inline">Edit demographics</span>
                            </Button>
                    }
                </div>
                <Stepper nonLinear activeStep={activeStep} className="tw-flex-1">
                    {
                        readonly ?
                            Object.keys(pages).map((page, index) => (
                                <Step key={index}>
                                    <StepLabel onClick={() => setActiveStep(index)} className="tw-cursor-pointer"></StepLabel>
                                </Step>
                            ))
                        :
                            Object.keys(pages).map((page, index) => (
                                <Step key={index} completed={pages[page].completed}>
                                    <StepLabel onClick={() => setActiveStep(index)} error={pages[page].touched && pages[page].error && index !== activeStep} className="tw-cursor-pointer"></StepLabel>
                                </Step>
                            ))
                    }
                </Stepper>
            </div>
            <div ref={elem} className="tw-mt-2 md:tw-mt-8 tw-pb-4 tw-pr-2 tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto tw-flex-1">
                {
                    values.filter(c => c.page === activeStep + 1).map((question) => {
                        let valid = validate(question)
                        return <div key={question.id} className={`tw-border-[1.5px] tw-transition-all tw-duration-300 ${valid ? 'tw-border-green-600' : 'tw-border-sky-400'} tw-rounded-2xl tw-bg-gradient-to-tr tw-from-sky-50 tw-to-sky-100 tw-relative`}>
                            <AnimatePresence>
                                {
                                    (valid) ?
                                        <motion.span variants={PopInAnim} initial="hidden" animate="visible" exit="hidden"><FaCheckCircle className="tw-text-green-600 tw-text-lg tw-absolute tw-top-3 tw-right-3" /></motion.span>
                                    : null
                                }
                            </AnimatePresence>
                            <Question question={question} id={question.id} setAnswer={setAnswer} disabled={readonly} />
                        </div>
                    })
                }
            </div>
            <div className="tw-pt-4 sm:tw-pb-4 tw-border-t-4 tw-border-sky-600 tw-flex tw-justify-between">
                <Button variant="contained" color="warning" onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
                    <HiOutlineArrowNarrowLeft className="tw-mr-2 tw-text-2xl" />
                    Previous
                </Button>
                <div className="tw-flex tw-gap-4">
                    <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep + 1)} disabled={activeStep === Object.keys(pages).length - 1}>
                        Next
                        <HiOutlineArrowNarrowRight className="tw-ml-2 tw-text-2xl" />
                    </Button>
                    <AnimatePresence>
                        {
                            (allPagesValid && !readonly) &&
                                <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden">
                                    <Button variant="contained" color="success" onClick={submitAssessment}>
                                        Submit
                                    </Button>
                                </motion.div>
                        }
                    </AnimatePresence>
                </div>
            </div>
        </div>
    )
}

export default Assessment