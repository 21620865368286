import { Provider } from 'react-redux'
import { store } from "./store/store"
import { BrowserRouter } from "react-router-dom";
import Loader from "./pages/Loader";
import Root from './Root';
import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from './mui_theme';

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <div className="App">
                            <Root />
                        </div>
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </Suspense>
    );
}

export default App;
